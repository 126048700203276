import { AuthConfig } from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {
    // Url of the Identity Provider
    issuer: 'https://auth-test.lsg-group.com/auth/realms/award',

    // URL of the SPA to redirect the user to after login
    redirectUri: window.location.origin + window.location.pathname,

    tokenEndpoint: 'protocol/openid-connect/token',

    // The SPA's id. The SPA is registered with this id at the auth-server
    // clientId: 'server.code',
    clientId: 'award',

    // Just needed if your auth server demands a secret. In general, this
    // is a sign that the auth server is not configured with SPAs in mind,
    // and it might not enforce further best practices vital for security
    // such applications.
    dummyClientSecret: 'c8A4y0YTLZW3y1JmXqaU3pB6liXtRF4R',

    responseType: 'code',

    // set the scope for the permissions the client should request
    // The first four are defined by OIDC.
    // Important: Request offline_access to get a refresh token
    scope: 'openid profile email offline_access',

    showDebugInformation: true,

    // Not recommended:
    // disablePKCI: true,
};
