import { ChangeDetectionStrategy, Component, computed, inject, input, signal } from '@angular/core';
import { InvoiceRecord, InvoiceStatus, InvoiceType } from '../../../shared/models/invoice';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { DecimalPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { PrimeTemplate } from 'primeng/api';
import { Table, TableModule } from 'primeng/table';
import { Router } from '@angular/router';
import { LocalizedDatePipe } from '../../../shared/pipes/localized-date.pipe';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { LayoutService } from '../../../shared/services/layout.service';

@Component({
    selector: 'app-overview-invoices',
    standalone: true,
    imports: [
        MatButtonToggleModule,
        MatIcon,
        TranslateModule,
        DecimalPipe,
        MatButton,
        PrimeTemplate,
        TableModule,
        NgClass,
        LocalizedDatePipe,
        MultiSelectModule,
        FormsModule,
        MatCheckbox,
        NgTemplateOutlet
    ],
    templateUrl: './overview-invoices.component.html',
    styleUrl: './overview-invoices.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewInvoicesComponent {
    private layoutService = inject(LayoutService);
    private translatePipe = inject(TranslatePipe);
    private router = inject(Router);

    protected readonly InvoiceStatus = InvoiceStatus;

    invoices = input.required<InvoiceRecord[]>();
    viewStyle = signal<'detailed' | 'compact'>('compact');

    changeViewStyle(viewStyle: 'detailed' | 'compact') {
        this.viewStyle.set(viewStyle);
    }

    searchValue: string | undefined;

    showClosedInvoices = signal(false);

    isSmallScreen = this.layoutService.isSmallScreen;

    filteredInvoices = computed(() => {
        const showClosedInvoices = this.showClosedInvoices();
        const invoices = this.invoices();

        return invoices.filter(invoice => invoice.status !== InvoiceStatus.Closed || showClosedInvoices);
    });

    clear(table: Table) {
        table.clear();
        this.searchValue = '';
    }

    getStatusClass(invoice: InvoiceRecord) {
        switch (invoice.status) {
            case InvoiceStatus.Open:
            case InvoiceStatus.WaitingForApproval:
            case InvoiceStatus.PartiallyApproved:
                return 'in-progress';

            case InvoiceStatus.Approved:
                return 'approved';

            case InvoiceStatus.Rejected:
                return 'rejected';
            case InvoiceStatus.Closed:
                return 'closed';
            default:
                return '';
        }
    }

    getStatusText(invoice: InvoiceRecord) {
        const invoiceStatus = invoice.status;

        switch (invoiceStatus) {
            case InvoiceStatus.Open:
                return 'open';
            case InvoiceStatus.WaitingForApproval:
                return 'waiting-for-approval';
            case InvoiceStatus.Approved:
                return 'approved';
            case InvoiceStatus.PartiallyApproved:
                return 'partially-approved';
            case InvoiceStatus.Rejected:
                return 'rejected';
            case InvoiceStatus.Closed:
                return 'closed';
            default:
                return '';
        }
    }

    checkIfInvoiceOrCredit(invoice: InvoiceRecord, invoiceText: string, creditText: string) {
        return invoice.sapRechngut === InvoiceType.Invoice ? this.translatePipe.transform(invoiceText) : this.translatePipe.transform(creditText);
    }

    navigate(invoice: InvoiceRecord) {
        void this.router.navigate(['invoice', invoice.uuid]);
    }
}
