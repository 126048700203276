@if (isLoading()) {
    <div class="progress-bar">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
}
<app-toolbar class="container"/>

<!--<app-overview-address class="container"/>-->

<app-overview-invoices class="container" [invoices]="invoiceCollection" />
