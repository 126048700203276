<p class="section-title">{{ "accounting-information" | translate }}</p>

<div class="invoice-position-tables">
    @for (position of invoice().positions; track $index; ) {
        <app-invoice-position-table style="position: relative;" [invoiceStatus]="invoice().status" [position]="position" [invoice]="invoice()"/>
        @if (!$last) {
            <mat-divider/>
        }
    }
</div>

<div class="button-row">
    @if (invoice().status === InvoiceStatus.Open) {
        <button mat-flat-button (click)="setAllPositionsFactualRight()" [disabled]="isReadOnly()">{{ "everything-factual" | translate }}</button>
    }
    @if (invoice().status === InvoiceStatus.WaitingForApproval) {
        <button mat-flat-button (click)="setAllPositionsPriceRight()" [disabled]="isReadOnly()">{{ "approve-everything" | translate }}</button>
    }
</div>
