[
  {
    "sapSpras": "E",
    "sapSaknr": "0010100020",
    "sapSaknrtext": "Goodwill - Accumulated Amortization",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010110010",
    "sapSaknrtext": "Brands, Customer Contracts",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010110020",
    "sapSaknrtext": "Brands, Customer Contracts-AccumAmorti",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010111010",
    "sapSaknrtext": "Customer Loyalty Programs",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010111020",
    "sapSaknrtext": "Customer Loyalty Programs-AccumAmorti",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010112020",
    "sapSaknrtext": "IntangibleAssWithInfiniteUse-AccumAmorti",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010120020",
    "sapSaknrtext": "Non-competition Agreements",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010120030",
    "sapSaknrtext": "Software Purchased",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010120031",
    "sapSaknrtext": "Customer Acquisition Cost",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010120040",
    "sapSaknrtext": "IntangibleAssetsManufact. by consolidCo.",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010120060",
    "sapSaknrtext": "Capitalized development expenses",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010120070",
    "sapSaknrtext": "Intangible Assets Under Construction",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010120080",
    "sapSaknrtext": "Advanced Payments for Intangible Assets",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010120082",
    "sapSaknrtext": "Advanced Payments Intang. Assets Clearing",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010121010",
    "sapSaknrtext": "Accum Amort - Intangible Assets - Other",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010121020",
    "sapSaknrtext": "Accum Amort - Non-Competition Agreements",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010121030",
    "sapSaknrtext": "Accum Amort - Software Purchased",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010121031",
    "sapSaknrtext": "Acc. Depr. Customer Aquisition",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010121040",
    "sapSaknrtext": "AccAmort-IntangAssManufact.ByConsolidCo.",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010121050",
    "sapSaknrtext": "AccumAmort-SoftwareInternallyDeveloped",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010121060",
    "sapSaknrtext": "AccumAmort-CapitalizedDevelopmentExpense",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010200020",
    "sapSaknrtext": "LandFinanceLeaseNotUsedByLessee(Invest)",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010212040",
    "sapSaknrtext": "Buildings - Finance Lease",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010213010",
    "sapSaknrtext": "Plants \u0026 Machinery",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010213011",
    "sapSaknrtext": "Plants \u0026 Machinery manual",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010213020",
    "sapSaknrtext": "Plants\u0026MachineryFinanLeasOperateByLessee",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010213030",
    "sapSaknrtext": "Plants\u0026MachinFinanLeasNotOperateByLessee",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010214010",
    "sapSaknrtext": "Vehicles - Catering",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010214020",
    "sapSaknrtext": "Vehicles-CaterTrucksFinLeaseUsedByLessee",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010214030",
    "sapSaknrtext": "Vehic-CaterTrucksFinLeaseNotUsedByLessee",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010214040",
    "sapSaknrtext": "Vehicles - Catering/Non-Catering",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010215010",
    "sapSaknrtext": "Factory \u0026 Office Equipment System",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010215011",
    "sapSaknrtext": "Factory \u0026 Office Equipment Manuell",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010215020",
    "sapSaknrtext": "Factory \u0026 Office Equipment LowValueAsset",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010216030",
    "sapSaknrtext": "FactoryOfficeEquipFinLeaseUsed By Lesse",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010216040",
    "sapSaknrtext": "FactoryOfficeEquipFinLeaseNotUsedByLesse",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010217010",
    "sapSaknrtext": "TangibleAssetsUnderConstruction-System",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010217011",
    "sapSaknrtext": "TangibleAssetsUnderConstruction-Manuell",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010218012",
    "sapSaknrtext": "Advanced Payments Tangible Ass. Clearing",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010220010",
    "sapSaknrtext": "Acc dep - Land (As Investment)",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010221010",
    "sapSaknrtext": "Acc Dep - Buildings (as Investment)",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010221020",
    "sapSaknrtext": "AccDep-BuildingFinLeaseNotUsed (invest)",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010230010",
    "sapSaknrtext": "Acc Dep - Land (used by owner)",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010231010",
    "sapSaknrtext": "Acc Dep - Buildings on Non-Owned Land",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010231011",
    "sapSaknrtext": "Acc Dep - Buildings on Non-Owned Land manual",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010231020",
    "sapSaknrtext": "AccDep-BuildingImprovmtNon-Owned Bldgs",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010232010",
    "sapSaknrtext": "AccDep -Buildings- Heritable Land Rights",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010232020",
    "sapSaknrtext": "Acc Dep - Buildings 0n Owned Land",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010232030",
    "sapSaknrtext": "AccDep-Buildings-ImprovementsOwned Bldgs",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  },
  {
    "sapSpras": "E",
    "sapSaknr": "0010232040",
    "sapSaknrtext": "AccDep-BuildingsFinLease\u0026 Used By Lessee Systemuse",
    "createdAt": "2024-09-05T09:41:09Z",
    "updatedAt": "2024-09-05T09:41:09Z"
  }
]
