import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mailName',
    standalone: true
})
export class MailNamePipe implements PipeTransform {
    transform(email: string): string {
        const namePart = email.split('@')[0];
        return namePart.replace(/[._-]/g, ' ').toLowerCase();
    }
}
