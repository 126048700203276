<div class="address-box">
    <div class="address-from">
        <p class="section-title">{{ 'from' | translate }}</p>
        <mat-list class="custom-list">
            <mat-list-item>
                <span>{{ 'organisation-unit' | translate }}</span>
                <span>{{ invoice().sapSenderabt }}</span>
            </mat-list-item>
            <mat-list-item>
                <span>{{ 'name' | translate }}</span>
                <span>{{ invoice().sapSender }}</span>
            </mat-list-item>
            <mat-list-item>
                <span>{{ 'company' | translate }}</span>
                <span>{{ invoice().sapGesellsch }}</span>
            </mat-list-item>
            <mat-list-item>
                <span>{{ 'telephone' | translate }}</span>
                <span>{{ invoice().sapTelefon }}</span>
            </mat-list-item>
        </mat-list>
    </div>
    <div class="address-to">
        <p class="section-title">{{ 'to' | translate }}</p>
        <mat-list class="custom-list">
            <mat-list-item>
                <span>{{ 'approver' | translate }}</span>
                <span>{{ invoice().sapEmpfang1 | mailName | capitalizeFirstLetterPipe }}</span>
            </mat-list-item>
            <mat-list-item>
                <span>{{ 'date' | translate }}</span>
                <span>{{ invoice().sapRechdat | localizedDate }}</span>
            </mat-list-item>
        </mat-list>
    </div>
    <div class="button-row">
        <button mat-stroked-button (click)="showOverview()">{{ 'show-overview' | translate }}</button>
    </div>
</div>
