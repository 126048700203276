import { ApplicationConfig, importProvidersFrom, LOCALE_ID, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { ROUTES } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
    HttpClient,
    provideHttpClient,
    withInterceptors,
    withInterceptorsFromDi
} from '@angular/common/http';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { TranslateLoader, TranslateModule, TranslateModuleConfig, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { httpErrorInterceptor } from './shared/interceptors/http-error.service';
import { environment } from '../environments/environment';
import { LocalizedDatePipe } from './shared/pipes/localized-date.pipe';
import { CapitalizeFirstLetterPipe } from './shared/pipes/capitalize-first-letter.pipe';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const translateModuleConfig: TranslateModuleConfig = {
    defaultLanguage: 'de',
    loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
    }
};

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({eventCoalescing: true}),
        provideRouter(ROUTES),
        provideAnimationsAsync(),
        provideHttpClient(
            withInterceptorsFromDi(),
            withInterceptors([httpErrorInterceptor])
        ),
        provideOAuthClient({
            resourceServer: {
                allowedUrls: [environment.apiUrl],
                sendAccessToken: true
            }
        }),
        {provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: {dateFormat: 'MM/dd/yyyy'}},
        {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 5000, panelClass: 'snack-bar'}},
        {provide: CapitalizeFirstLetterPipe},
        {provide: LocalizedDatePipe},
        {provide: TranslatePipe},
        importProvidersFrom(TranslateModule.forRoot(translateModuleConfig)),
        {provide: LOCALE_ID, useValue: 'en-US'}
    ]
};
