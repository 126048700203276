export interface InvoiceRecord {
    uuid: string;
    sapBelnr: string;
    sapBukrs: string;
    sapGjahr: string;
    sapRechngut: InvoiceType;
    sapLifnr: string;
    sapLifnam: string;
    sapRechdat: Date;
    sapWaers: string;
    sapNetto: number;
    sapBrutto: number;
    sapBstnr: string;
    sapXblnr: string;
    sapEmpfang1: string;
    sapEmpfang2: string;
    sapArcdocid: string;
    sapSender: string;
    sapGesellsch: string;
    sapSenderabt: string;
    sapTelefon: string;
    sapSkomment: string;
    sapBruttoeur: number;
    sapDienst: string;
    sapReBelnr: string;
    sapLangu: string;
    sapEmpfangk: string;
    sapUrl: string;
    status: InvoiceStatus;
    comment: string;
    changedBy: string;
    changedByEmail: string;
    changedByForename: string;
    changedBySurname: string;
    createdAt: Date;
    updatedAt: Date;
    positions: InvoicePositionRecord[];
    attachments: InvoiceAttachmentRecord[];
}

export interface InvoicePositionRecord {
    id: number;
    sapBuzei: string;
    sapPossperr: string;
    sapInvesta: string;
    sapHkonta: string;
    sapKostla: string;
    sapAufnra: string;
    sapPsPosida: string;
    sapBezeka: string;
    sapNetto: number;
    sapZbukrs: string;
    sapKostltext: string;
    sapSaknrtextE: string;
    sapSaknrtextD: string;
    factualRight: boolean;
    priceRight: boolean;
    createdAt: Date;
    updatedAt: Date;
}

export interface InvoiceAttachmentRecord {
    id: number;
    fileName: string;
    fileSize: number;
    createdAt: Date;
    updatedAt: Date;
    file: File;
}

export enum InvoiceType {
    Invoice = 'R',
    Credit = 'G'
}

export enum InvoiceStatus {
    Open,
    WaitingForApproval,
    Approved,
    Rejected,
    PartiallyApproved,
    Closed
}
