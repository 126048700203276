import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { InvoiceRecord } from '../../../shared/models/invoice';
import { TranslateModule } from '@ngx-translate/core';
import { MatListModule } from '@angular/material/list';
import { MailToNamePipe } from '../../../shared/pipes/mail-to-name.pipe';
import { LocalizedDatePipe } from '../../../shared/pipes/localized-date.pipe';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';

@Component({
    selector: 'app-invoice-address',
    standalone: true,
    imports: [
        TranslateModule,
        MatListModule,
        MailToNamePipe,
        MatButton,
        LocalizedDatePipe,
    ],
    templateUrl: './invoice-address.component.html',
    styleUrl: './invoice-address.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceAddressComponent {
    private router = inject(Router);
    invoice = input.required<InvoiceRecord>();

    showOverview() {
        void this.router.navigate(['overview']);
    }
}
