import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    inject,
    input,
    untracked
} from '@angular/core';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { InvoiceService } from '../../../shared/services/invoice.service';
import { InvoiceRecord, InvoiceStatus } from '../../../shared/models/invoice';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { MatCard, MatCardContent, MatCardTitle } from '@angular/material/card';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ChangedByPipe } from '../../../shared/pipes/changed-by.pipe';
import { CapitalizeFirstLetterPipe } from '../../../shared/pipes/capitalize-first-letter.pipe';

@Component({
    selector: 'app-comment',
    standalone: true,
    imports: [
        MatFormField,
        MatInput,
        TranslateModule,
        MatLabel,
        DatePipe,
        MatCard,
        MatCardContent,
        MatCardTitle,
        ReactiveFormsModule,
        MatError,
        ChangedByPipe,
        CapitalizeFirstLetterPipe
    ],
    host: {
        '[class.hidden]': 'hideComments()',   // <-- signal
    },
    templateUrl: './invoice-comment.component.html',
    styleUrl: './invoice-comment.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceCommentComponent {
    invoiceService = inject(InvoiceService);
    invoice = input.required<InvoiceRecord>();
    isReadOnly = this.invoiceService.isReadOnly;

    commentChanges = computed(() => {
        const invoiceChanges = this.invoiceService.invoiceChanges();
        return invoiceChanges.filter(invoiceChange => invoiceChange.comment.length > 0 && invoiceChange.changedBy !== 'SERVICE');
    });

    hideComments = computed(() => {
        return !(this.invoice().status === InvoiceStatus.Open || this.invoice().status === InvoiceStatus.WaitingForApproval || this.commentChanges().length > 0);
    });

    formGroup = new FormGroup({
        invoiceComment: new FormControl({
            value: '',
            disabled: this.isReadOnly()
        }, {
            validators: [
                Validators.maxLength(180),
            ],
            nonNullable: true
        })
    });

    get invoiceComment() {
        return this.formGroup.controls.invoiceComment;
    }

    constructor() {
        effect(() => {
            const invoice = this.invoice();

            untracked(() => {
                if (invoice) {
                    this.invoiceComment.setValue(invoice.comment)
                }
            });
        });
    }

    protected readonly InvoiceStatus = InvoiceStatus;

    updateComment() {
        if (this.formGroup.valid && this.formGroup.value !== null) {
            this.invoiceService.updateComment(this.invoiceComment.value);
        }
    }
}
