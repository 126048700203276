import { inject, Injectable, signal } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from '../../auth.config';
import { JwtPayload } from '../models/jwt-payload';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private oAuthService = inject(OAuthService);
    private router = inject(Router);

    claims = signal<JwtPayload | null>(null);

    initAuthConfiguration() {
        this.oAuthService.configure(authConfig);
        this.oAuthService.setupAutomaticSilentRefresh();
        void this.oAuthService.loadDiscoveryDocumentAndLogin().then(() => {
            this.claims.set(this.oAuthService.getIdentityClaims() as JwtPayload);
        });
    }

    async logout() {
        this.oAuthService.logOut();
        this.claims.set(null);
        return this.router.navigate(['login']);
    }
}
