import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shorten',
    standalone: true
})
export class ShortenPipe implements PipeTransform {
    transform(value: string, maxLength = 40): string {
        if (value.length > maxLength) {
            return value.substring(0, maxLength - 3) + '...';
        }
        return value;
    }
}
