@if (positionLocked()) {
    <div class="position-disabled"></div>
}

<div class="position">
    <div class="table-container">
        <table mat-table [dataSource]="[position()]" class="custom-table mat-elevation-z2">
            <ng-container matColumnDef="positionNr">
                <th mat-header-cell *matHeaderCellDef [style.width.%]="10">{{ "position-nr" | translate }}</th>
                <td mat-cell *matCellDef="let position">{{ position.sapBuzei }}</td>
            </ng-container>

            <ng-container matColumnDef="text">
                <th mat-header-cell *matHeaderCellDef [style.width.%]="10">{{ "text" | translate }}</th>
                <td mat-cell *matCellDef="let position">{{ position.sapBezeka === '' ? '-' : position.sapBezeka }}</td>
            </ng-container>

            <ng-container matColumnDef="netAmount">
                <th mat-header-cell *matHeaderCellDef [style.width.%]="10">{{ "net-amount" | translate }}</th>
                <td mat-cell *matCellDef="let position">{{ position.sapNetto | number:'1.2-2' }}</td>
            </ng-container>

            <ng-container matColumnDef="pspElement">
                <th mat-header-cell *matHeaderCellDef [style.width.%]="10">{{ "psp-element" | translate }}</th>
                <td mat-cell *matCellDef="let position">{{ position.sapPsPosida === '' ? '-' : position.sapPsPosida }}</td>
            </ng-container>

            <ng-container matColumnDef="order">
                <th mat-header-cell *matHeaderCellDef [style.width.%]="10">{{ "order" | translate }}</th>
                <td mat-cell *matCellDef="let position">{{ position.sapAufnra }}</td>
            </ng-container>

            <ng-container matColumnDef="costCenter">
                <th mat-header-cell *matHeaderCellDef [style.width.%]="25">{{ "cost-center" | translate }}</th>
                <td mat-cell *matCellDef="let position">{{ position.sapKostla }} {{ position.sapKostltext }}</td>
            </ng-container>

            <ng-container matColumnDef="account">
                <th mat-header-cell *matHeaderCellDef [style.width.%]="25">{{ "account" | translate }}</th>
                <td mat-cell *matCellDef="let position">{{ position.sapHkonta }} {{ sapSaknrtextTranslated() }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedPositionColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedPositionColumns"></tr>
        </table>
    </div>

    @if (invoiceStatus() > InvoiceStatus.Open) {
        <app-invoice-position-history [invoiceStatus]="invoiceStatus()" [position]="position()"/>
    }

    <div class="multiple-button-col">
        <!--TODO wenn erwünscht, wieder einblenden und an merge weiterarbeiten-->
        <div>
            @if (invoiceStatus() === InvoiceStatus.Open) {
                <button (click)="editPosition()" mat-icon-button [disabled]="isReadOnly()" [matTooltip]="translatePipe.transform('edit-position')" matTooltipPosition="above">
                    <mat-icon>edit</mat-icon>
                </button>
            }
            <!--<button (click)="copyPosition()" mat-icon-button [matTooltip]="translatePipe.transform('copy-position')" matTooltipPosition="above" matTooltipHideDelay="500">
                <mat-icon>difference</mat-icon>
            </button>
            <button (click)="mergePositions()" mat-icon-button [matTooltip]="translatePipe.transform('merge-positions')" matTooltipPosition="above" matTooltipHideDelay="500">
                <mat-icon>call_merge</mat-icon>
            </button>-->
        </div>
        <mat-slide-toggle [attr.style]="invoiceStatus() === InvoiceStatus.Open ? '' : 'display: none'"
                          [ngModel]="position().factualRight"
                          [disabled]="invoiceStatus() !== InvoiceStatus.Open || isReadOnly()"
                          labelPosition="before"
                          (change)="invoiceService.updatePosition(position(), 'factualRight', $event.checked)">
            {{ "position.factual-right" | translate }}
        </mat-slide-toggle>
        @if (invoiceStatus() !== InvoiceStatus.Open) {
            <mat-slide-toggle [attr.style]="invoiceStatus() === InvoiceStatus.WaitingForApproval ? '' : 'display: none'"
                              [ngModel]="position().priceRight"
                              [disabled]="invoiceStatus() === InvoiceStatus.Approved || invoiceStatus() === InvoiceStatus.Rejected || invoiceStatus() === InvoiceStatus.PartiallyApproved || isReadOnly()"
                              labelPosition="before"
                              (change)="invoiceService.updatePosition(position(), 'priceRight', $event.checked)">
                {{ "position.price-right" | translate }}
            </mat-slide-toggle>
        }
    </div>
</div>
