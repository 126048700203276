{
  "uuid": "yuMrQU0i",
  "sapBelnr": "0110000014",
  "sapBukrs": "5007",
  "sapGjahr": "2024",
  "sapRechngut": "R",
  "sapLifnr": "0000341242",
  "sapLifnam": "Alpha Flight a.s.",
  "sapRechdat": "2024-02-05T00:00:00Z",
  "sapWaers": "CZK",
  "sapNetto": 321597.96,
  "sapBrutto": 387445.81,
  "sapBstnr": "",
  "sapXblnr": "152832492_ WALKT",
  "sapEmpfang1": "local-user@keycloak.lsg",
  "sapEmpfang2": "dirk.bunk@lhind.dlh.de",
  "sapArcdocid": "02C91EEBF4331EDF80F43B314E2C88DA",
  "sapSender": "Ralph Bernhard",
  "sapGesellsch": "LSG",
  "sapSenderabt": "FRA Z/FAGP",
  "sapTelefon": "06102-240395",
  "sapSkomment": "Walkthroug",
  "sapBruttoeur": 12733.78,
  "sapDienst": "TEST ENTRY",
  "sapReBelnr": "",
  "sapLangu": "DE",
  "sapEmpfangk": "john.doe@award.lsg.cloud",
  "sapUrl": "https://archiveservertest.dlh.de:8090/SAPALink/ContentServer?get\u0026contRep=DR\u0026docId=02C91EEBF4331EDF80F43B314E2C88DA\u0026pVersion=0045",
  "status": 0,
  "comment": "",
  "changedBy": "local-user",
  "changedByEmail": "local-user@keycloak.lsg",
  "changedByForename": "Local",
  "changedBySurname": "User",
  "createdAt": "2024-08-01T15:56:28Z",
  "updatedAt": "2024-08-12T08:22:46Z",
  "positions": [
    {
      "id": 25,
      "sapBuzei": "002",
      "sapPossperr": "",
      "sapInvesta": "A",
      "sapHkonta": "0010110020",
      "sapKostla": "5007174900",
      "sapAufnra": "",
      "sapPsPosida": "",
      "sapBezeka": "",
      "sapNetto": 18752.45,
      "sapZbukrs": "0813",
      "sapKostltext": "0813-DUMMY",
      "sapSaknrtextE": "Brands, Customer Contracts-AccumAmorti",
      "sapSaknrtextD": "Marken, Kunde Vertrag",
      "factualRight": false,
      "priceRight": false,
      "createdAt": "2024-08-01T15:56:28Z",
      "updatedAt": "2024-08-12T08:22:46Z"
    },
    {
      "id": 26,
      "sapBuzei": "003",
      "sapPossperr": "",
      "sapInvesta": "A",
      "sapHkonta": "0010110020",
      "sapKostla": "5007174900",
      "sapAufnra": "",
      "sapPsPosida": "",
      "sapBezeka": "",
      "sapNetto": 302845.51,
      "sapZbukrs": "0813",
      "sapKostltext": "0813-DUMMY",
      "sapSaknrtextE": "Brands, Customer Contracts-AccumAmorti",
      "sapSaknrtextD": "Marken, Kunde Vertrag",
      "factualRight": false,
      "priceRight": false,
      "createdAt": "2024-08-01T15:56:28Z",
      "updatedAt": "2024-08-12T08:22:46Z"
    }
  ],
  "attachments": []
}
