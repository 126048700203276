import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fileSize',
    standalone: true
})
export class FileSizePipe implements PipeTransform {
    transform(size: number): string {
        const sizeKb = size / 1024;

        if (sizeKb > 1024) {
            return `${(sizeKb / 1024).toFixed(2)} MB`;
        } else {
            return `${(sizeKb).toFixed(2)} KB`;
        }
    }
}
