<p class="section-title">{{ checkIfInvoiceOrCredit('invoice', 'credit') }}</p>

<div class="table-container">
    <table mat-table [dataSource]="[invoice()]" class="custom-table mat-elevation-z2">
        <ng-container matColumnDef="supplier">
            <th mat-header-cell *matHeaderCellDef>{{ 'supplier' | translate }}</th>
            <td mat-cell *matCellDef="let invoice">{{ invoice.sapLifnam }}</td>
        </ng-container>

        <ng-container matColumnDef="creditor">
            <th mat-header-cell *matHeaderCellDef>{{ 'creditor' | translate }}</th>
            <td mat-cell *matCellDef="let invoice">{{ invoice.sapLifnr }}</td>
        </ng-container>

        <ng-container matColumnDef="sapDocumentNr">
            <th mat-header-cell *matHeaderCellDef>{{ 'sap-receipt' | translate }}</th>
            <td mat-cell *matCellDef="let invoice">{{ invoice.sapBelnr }}</td>
        </ng-container>

        <ng-container matColumnDef="invoiceNr">
            <th mat-header-cell *matHeaderCellDef>{{ checkIfInvoiceOrCredit('invoice-nr', 'credit-nr') }}</th>
            <td mat-cell *matCellDef="let invoice">{{ invoice.sapXblnr }}</td>
        </ng-container>

        <ng-container matColumnDef="invoiceDate">
            <th mat-header-cell *matHeaderCellDef>{{ checkIfInvoiceOrCredit('invoice-date', 'credit-date') }}</th>
            <td mat-cell *matCellDef="let invoice">{{ invoice.sapRechdat | localizedDate }}</td>
        </ng-container>

        <ng-container matColumnDef="netAmount">
            <th mat-header-cell *matHeaderCellDef>{{ 'net-amount' | translate }}</th>
            <td mat-cell *matCellDef="let invoice">{{ invoice.sapNetto | number:'1.2-2' }}</td>
        </ng-container>

        <ng-container matColumnDef="grossAmount">
            <th mat-header-cell *matHeaderCellDef>{{ 'gross-amount' | translate }}</th>
            <td mat-cell *matCellDef="let invoice">{{ invoice.sapBrutto | number:'1.2-2' }}</td>
        </ng-container>

        <ng-container matColumnDef="currency">
            <th mat-header-cell *matHeaderCellDef>{{ 'currency' | translate }}</th>
            <td mat-cell *matCellDef="let invoice">{{ invoice.sapWaers }}</td>
        </ng-container>

        <ng-container matColumnDef="localAmount">
            <th mat-header-cell *matHeaderCellDef>{{ 'local-amount' | translate }}</th>
            <td mat-cell *matCellDef="let invoice">{{ invoice.sapBruttoeur | number:'1.2-2' }}</td>
        </ng-container>

        <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef>{{ 'note' | translate }}</th>
            <td mat-cell *matCellDef="let invoice">{{ invoice.sapSkomment === '' ? '-' : invoice.sapSkomment }}</td>
        </ng-container>

        <ng-container matColumnDef="bukrs">
            <th mat-header-cell *matHeaderCellDef>{{ 'bukrs' | translate }}</th>
            <td mat-cell *matCellDef="let invoice">{{ invoice.sapBukrs }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedInvoiceColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedInvoiceColumns"></tr>
    </table>
</div>

@if (savedAttachments().length > 0 || newAttachments().length > 0 || deletedAttachments().length > 0) {
    <div class="attached-documents">
        <div>
            @if (newAttachments().length > 0 || deletedAttachments().length > 0) {
                <span class="text-bold">{{ 'attached-documents-unsaved' | translate }}</span>
            } @else {
                <span class="text-bold">{{ 'attached-documents' | translate }}</span>
            }
            <mat-list>
                @for (file of savedAttachments(); track $index) {
                    <mat-list-item>
                        <mat-icon matListItemIcon>document_scanner</mat-icon>
                        {{ file.fileName | shorten }} ({{ file.fileSize | fileSize }})
                        <button mat-icon-button color="primary" (click)="downloadAttachment(file)" [matTooltip]="translatePipe.transform('download-attachment')" matTooltipPosition="above">
                            <mat-icon>download</mat-icon>
                        </button>
                        @if (invoice().status === InvoiceStatus.Open) {
                            <button mat-icon-button (click)="removeAttachment(file)" [matTooltip]="translatePipe.transform('remove-attachment')" matTooltipPosition="above">
                                <mat-icon>delete</mat-icon>
                            </button>
                        }
                    </mat-list-item>
                }

                @for (file of deletedAttachments(); track $index) {
                    <mat-list-item class="attachment-deleted">
                        <mat-icon matListItemIcon>document_scanner</mat-icon>
                        <s>{{ file.fileName | shorten }} ({{ file.fileSize | fileSize }})</s>
                    </mat-list-item>
                }

                @for (file of newAttachments(); track $index) {
                    <mat-list-item class="attachment-new">
                        <mat-icon matListItemIcon>document_scanner</mat-icon>
                        {{ file.fileName | shorten }} ({{ file.fileSize | fileSize }})
                        @if (invoice().status === InvoiceStatus.Open) {
                            <button mat-icon-button (click)="removeAttachment(file)" [matTooltip]="translatePipe.transform('remove-attachment')" matTooltipPosition="above">
                                <mat-icon>delete</mat-icon>
                            </button>
                        }
                    </mat-list-item>
                }
            </mat-list>
        </div>
    </div>
}

<div class="button-row">
    <button class="secondary" (click)="openInvoice()" mat-stroked-button>{{ checkIfInvoiceOrCredit('show-invoice', 'show-credit') }}</button>

    @if (invoice().status === InvoiceStatus.Open) {
        <input #attachDoc type="file" hidden (change)="attachDocuments($event)" accept=".pdf, .doc, .xls, image/*" multiple>
        <button class="secondary" (click)="attachDoc.click()" mat-stroked-button>{{ 'attach-document' | translate }}</button>
    }
    <button class="secondary" (click)="printPage()" mat-stroked-button>{{ 'print' | translate }}</button>
</div>
