@if (isLoading()) {
    <div class="progress-bar">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
}

@if (invoice(); as invoice) {
    <app-toolbar class="container" [invoice]="invoice"/>

    <app-invoice-address class="container" [invoice]="invoice"/>

    <app-document-information class="container" [invoice]="invoice"/>

    <app-invoice-table class="container" [invoice]="invoice"/>

    <app-accounting-information class="container" [invoice]="invoice"/>

    <app-comment class="container" [invoice]="invoice"/>

    <div class="container button-row"
         [attr.style]="getStyle(invoice.status, 'display: none')">
        <button mat-flat-button (click)="saveChanges()" [disabled]="isReadOnly()">
            {{ 'save' | translate }}
        </button>

        <button mat-flat-button (click)="finalizeInvoice()" [disabled]="isReadOnly() || !isInvoiceCorrect()">
            @if (invoice.status === InvoiceStatus.Open) {
                {{ 'factual-approve' | translate }}
            } @else if (invoice.status === InvoiceStatus.WaitingForApproval) {
                {{ 'price-approve' | translate }}
            }
        </button>

        <button mat-flat-button (click)="finalizeInvoice()" [disabled]="isReadOnly() || isInvoiceCorrect()">
            @if (invoice.status === InvoiceStatus.Open) {
                {{ 'factual-reject' | translate }}
            } @else if (invoice.status === InvoiceStatus.WaitingForApproval) {
                {{ 'price-reject' | translate }}
            }
        </button>
    </div>
}
