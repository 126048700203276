import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from '../../services/auth.service';
import { InvoiceRecord, InvoiceType } from '../../models/invoice';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NgOptimizedImage } from '@angular/common';
import { CapitalizeFirstLetterPipe } from '../../pipes/capitalize-first-letter.pipe';
import { MatDialog } from '@angular/material/dialog';
import { HelpDialogComponent } from '../dialogs/help-dialog/help-dialog.component';
import { InvoiceService } from '../../services/invoice.service';
import { LayoutService } from '../../services/layout.service';

@Component({
    selector: 'app-toolbar',
    standalone: true,
    imports: [
        MatButtonModule,
        TranslateModule,
        MatIconModule,
        MatMenuModule,
        NgOptimizedImage,
        CapitalizeFirstLetterPipe
    ],
    templateUrl: './toolbar.component.html',
    styleUrl: './toolbar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent {
    private authService = inject(AuthService);
    private translateService = inject(TranslateService);
    private layoutService = inject(LayoutService);
    private dialog = inject(MatDialog);
    private invoiceService = inject(InvoiceService);

    protected readonly InvoiceType = InvoiceType;

    invoice = input<InvoiceRecord>();
    isError = input(false);
    currentLang = this.invoiceService.currentLang;

    claims = this.authService.claims;
    isSmallScreen = this.layoutService.isSmallScreen;

    checkForPositionLock(): boolean {
        return this.invoice()?.positions.some(position => position.sapPossperr !== '') || false;
    }

    logout() {
        void this.authService.logout();
    }

    changeLocale(locale: 'de' | 'en') {
        this.translateService.use(locale);
    }

    openHelp() {
        this.dialog.open<HelpDialogComponent, null, null>(HelpDialogComponent, {
            autoFocus: 'first-heading'
        });
    }
}
