<div class="address-box">
    <mat-list class="custom-list">
        <mat-list-item>
            <span>{{ 'name' | translate }}</span>
            <span>{{ claims()?.name }}</span>
        </mat-list-item>
        <mat-list-item>
            <span>{{ 'company' | translate }}</span>
            <span>LSG</span>
        </mat-list-item>
    </mat-list>
</div>
