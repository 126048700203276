[
  {
    "sapBukrs": "0813",
    "sapKostl": "0813000000",
    "sapKostltext": "0813-DUMMY",
    "createdAt": "2024-08-01T16:01:54Z",
    "updatedAt": "2024-08-01T16:01:54Z"
  },
  {
    "sapBukrs": "0814",
    "sapKostl": "0814000000",
    "sapKostltext": "0814 MIGRATION",
    "createdAt": "2024-08-01T16:01:54Z",
    "updatedAt": "2024-08-01T16:01:54Z"
  },
  {
    "sapBukrs": "0807",
    "sapKostl": "0807998970",
    "sapKostltext": "0841 AEROCOCINA S.A.",
    "createdAt": "2024-08-01T16:01:54Z",
    "updatedAt": "2024-08-01T16:01:54Z"
  },
  {
    "sapBukrs": "0807",
    "sapKostl": "0807998960",
    "sapKostltext": "0842 LSG SKY CHEFS A",
    "createdAt": "2024-08-01T16:01:54Z",
    "updatedAt": "2024-08-01T16:01:54Z"
  },
  {
    "sapBukrs": "0807",
    "sapKostl": "0807998950",
    "sapKostltext": "0852 FORTALEZA SERVI",
    "createdAt": "2024-08-01T16:01:54Z",
    "updatedAt": "2024-08-01T16:01:54Z"
  },
  {
    "sapBukrs": "0807",
    "sapKostl": "0807998951",
    "sapKostltext": "0853 BELEM SERVIÇOS",
    "createdAt": "2024-08-01T16:01:54Z",
    "updatedAt": "2024-08-01T16:01:54Z"
  },
  {
    "sapBukrs": "0807",
    "sapKostl": "0807998952",
    "sapKostltext": "0854 NATAL CATERING",
    "createdAt": "2024-08-01T16:01:54Z",
    "updatedAt": "2024-08-01T16:01:54Z"
  },
  {
    "sapBukrs": "0870",
    "sapKostl": "0870000000",
    "sapKostltext": "0870-DUMMY",
    "createdAt": "2024-08-01T16:01:54Z",
    "updatedAt": "2024-08-01T16:01:54Z"
  },
  {
    "sapBukrs": "0876",
    "sapKostl": "0876000000",
    "sapKostltext": "0876 MIGRATION",
    "createdAt": "2024-08-01T16:01:54Z",
    "updatedAt": "2024-08-01T16:01:54Z"
  },
  {
    "sapBukrs": "0881",
    "sapKostl": "0881102910",
    "sapKostltext": "EQM/ P\u0026L 088110",
    "createdAt": "2024-08-01T16:01:54Z",
    "updatedAt": "2024-08-01T16:01:54Z"
  },
  {
    "sapBukrs": "0881",
    "sapKostl": "0881302910",
    "sapKostltext": "LOG P\u0026L 088130",
    "createdAt": "2024-08-01T16:01:54Z",
    "updatedAt": "2024-08-01T16:01:54Z"
  },
  {
    "sapBukrs": "0881",
    "sapKostl": "0881002910",
    "sapKostltext": "MGT P\u0026L 088100",
    "createdAt": "2024-08-01T16:01:54Z",
    "updatedAt": "2024-08-01T16:01:54Z"
  },
  {
    "sapBukrs": "0881",
    "sapKostl": "0881000000",
    "sapKostltext": "MIGRATION 0881",
    "createdAt": "2024-08-01T16:01:54Z",
    "updatedAt": "2024-08-01T16:01:54Z"
  },
  {
    "sapBukrs": "0881",
    "sapKostl": "0881902910",
    "sapKostltext": "PE UK/P\u0026L 088190",
    "createdAt": "2024-08-01T16:01:54Z",
    "updatedAt": "2024-08-01T16:01:54Z"
  },
  {
    "sapBukrs": "0881",
    "sapKostl": "0881802910",
    "sapKostltext": "PE US/P\u0026L 088180",
    "createdAt": "2024-08-01T16:01:54Z",
    "updatedAt": "2024-08-01T16:01:54Z"
  },
  {
    "sapBukrs": "0864",
    "sapKostl": "0864153490",
    "sapKostltext": "UMZUG/NEUBAU FRA 08",
    "createdAt": "2024-08-01T16:01:54Z",
    "updatedAt": "2024-08-01T16:01:54Z"
  }
]
