<h2 mat-dialog-title>{{ 'email-dialog.title' | translate }}</h2>

<mat-dialog-content>
    <form [formGroup]="emailForm">
        <mat-form-field appearance="outline">
            <mat-label>{{ 'email-dialog.label' | translate }}</mat-label>
            <input formControlName="email" type="email" matInput>
        </mat-form-field>
        @if (email.hasError('email')) {
            <mat-error>
                {{ 'email-dialog.error' | translate }}
            </mat-error>
        }
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-flat-button (click)="dialogRef.close()">{{ 'close' | translate }}</button>
    <button mat-flat-button (click)="saveAndSend()">{{ 'OK' }}</button>
</mat-dialog-actions>
