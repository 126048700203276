import { computed, inject, Injectable, signal } from '@angular/core';
import { InvoiceAttachmentRecord, InvoicePositionRecord, InvoiceRecord, InvoiceStatus } from '../models/invoice';
import { InvoiceChange, InvoiceHistoryRecord, InvoicePositionChange } from '../models/invoice-history';
import { BackendService } from './backend.service';
import {
    concat,
    defaultIfEmpty,
    EMPTY,
    finalize,
    last,
    map,
    mergeAll,
    MonoTypeOperatorFunction,
    switchMap,
    tap
} from 'rxjs';
import { AuthService } from './auth.service';
import { TranslateService } from '@ngx-translate/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

@Injectable({
    providedIn: 'root'
})
export class InvoiceService {
    private backendService = inject(BackendService);
    private authService = inject(AuthService);
    private translateService = inject(TranslateService);

    isLoading = signal(true);
    invoiceOrig = signal<InvoiceRecord | null>(null);
    invoice = signal<InvoiceRecord | null>(null);
    invoiceHistory = signal<InvoiceHistoryRecord[]>([]);
    invoiceCollection = signal<InvoiceRecord[]>([]);

    currentLang = toSignal(this.translateService.onLangChange.pipe(map(onLangChange => onLangChange.lang)), {initialValue: this.translateService.currentLang});

    userinfo = toSignal(toObservable(this.authService.claims).pipe(
        switchMap(() => this.backendService.getUserinfo())
    ), {initialValue: null});

    isReadOnly = computed(() => {
        const userinfo = this.userinfo();
        const invoice = this.invoice();
        const invoiceHistory = this.invoiceHistory();
        if (!userinfo || !invoice) return true;

        if (invoice.status >= InvoiceStatus.Approved) return true;

        if (invoice.status === InvoiceStatus.WaitingForApproval) {
            const record = invoiceHistory.find(record => record.status === InvoiceStatus.WaitingForApproval);
            if (record) {
                return record?.changedBy === userinfo.username;
            }
            return userinfo.username === invoice.changedBy;
        }

        return false;
    });

    isInvoiceCorrect = computed(() => {
        const invoice = this.invoice();
        if (!invoice) return false;

        return (invoice.status === InvoiceStatus.Open && invoice.positions.every((position) => position.factualRight)) || (invoice.status === InvoiceStatus.WaitingForApproval && invoice.positions.every((position) => position.priceRight));
    });

    invoiceChanges = computed(() => {
        const invoiceOrig = this.invoiceOrig();
        const invoiceHistory = this.invoiceHistory();
        let invoiceChanges: InvoiceChange[] = [];

        // Get all changes from the invoice history
        if (invoiceHistory.length > 0) {
            invoiceChanges = invoiceHistory.reduce((invoiceChanges: InvoiceChange[], invoiceHistoryRecord) => {
                let invoicePositionChanges: InvoicePositionChange[] = [];
                if (invoiceHistoryRecord.positions != null) {
                    invoicePositionChanges = invoiceHistoryRecord.positions.reduce((invoicePositionChanges: InvoicePositionChange[], invoicePositionHistoryRecord) => {
                        return [
                            ...invoicePositionChanges, {
                                id: invoicePositionHistoryRecord.invoicePositionId,
                                factualRight: invoicePositionHistoryRecord.factualRight,
                                priceRight: invoicePositionHistoryRecord.priceRight,
                                changedBy: invoiceHistoryRecord.changedBy,
                                changedByEmail: invoiceHistoryRecord.changedByEmail,
                                changedByForename: invoiceHistoryRecord.changedByForename,
                                changedBySurname: invoiceHistoryRecord.changedBySurname,
                                createdAt: invoicePositionHistoryRecord.createdAt,
                                updatedAt: invoicePositionHistoryRecord.updatedAt
                            }
                        ];
                    }, []);

                    return [
                        ...invoiceChanges, {
                            status: invoiceHistoryRecord.status,
                            comment: invoiceHistoryRecord.comment,
                            positions: invoicePositionChanges,
                            changedBy: invoiceHistoryRecord.changedBy,
                            changedByEmail: invoiceHistoryRecord.changedByEmail,
                            changedByForename: invoiceHistoryRecord.changedByForename,
                            changedBySurname: invoiceHistoryRecord.changedBySurname,
                            createdAt: invoiceHistoryRecord.createdAt,
                            updatedAt: invoiceHistoryRecord.updatedAt
                        }
                    ];
                }

                return [...invoiceChanges];

            }, []);
        }

        // Add the information from the current invoice record
        if (invoiceOrig) {
            const invoicePositionChanges = invoiceOrig.positions.reduce((invoicePositionChanges: InvoicePositionChange[], invoicePositionRecord) => {
                return [
                    ...invoicePositionChanges, {
                        id: invoicePositionRecord.id,
                        factualRight: invoicePositionRecord.factualRight,
                        priceRight: invoicePositionRecord.priceRight,
                        changedBy: invoiceOrig.changedBy,
                        changedByEmail: invoiceOrig.changedByEmail,
                        changedByForename: invoiceOrig.changedByForename,
                        changedBySurname: invoiceOrig.changedBySurname,
                        createdAt: invoicePositionRecord.createdAt,
                        updatedAt: invoicePositionRecord.updatedAt
                    }
                ];
            }, []);

            return [...invoiceChanges, {
                status: invoiceOrig.status,
                comment: invoiceOrig.comment,
                positions: invoicePositionChanges,
                changedBy: invoiceOrig.changedBy,
                changedByEmail: invoiceOrig.changedByEmail,
                changedByForename: invoiceOrig.changedByForename,
                changedBySurname: invoiceOrig.changedBySurname,
                createdAt: invoiceOrig.createdAt,
                updatedAt: invoiceOrig.updatedAt
            }];
        } else {
            return invoiceChanges;
        }
    });

    savedAttachments = computed(() => {
        const invoice = this.invoice();
        return invoice ? invoice.attachments.filter(attachment => attachment.id !== 0) : [];
    });

    newAttachments = computed(() => {
        const invoice = this.invoice();
        return invoice ? invoice.attachments.filter(attachment => attachment.id === 0) : [];
    });

    deletedAttachments = computed(() => {
        const invoice = this.invoice();
        const invoiceOrig = this.invoiceOrig();

        if (!invoice || !invoiceOrig) return [];

        return invoiceOrig.attachments.filter(attachment => {
            return !invoice.attachments.includes(attachment);
        });
    });

    private calculateInvoiceHistory(invoice: InvoiceRecord) {
        this.backendService.getInvoiceHistory(invoice.uuid).pipe(
            tap(history => this.invoiceHistory.set(history))
        ).subscribe();
    }

    private calculateInvoiceHistoryTest() {
        this.backendService.getInvoiceHistoryTest().pipe(
            tap(history => this.invoiceHistory.set(history))
        ).subscribe();
    }

    private processInvoice(): MonoTypeOperatorFunction<InvoiceRecord> {
        return (source) => source.pipe(
            tap(inv => this.calculateInvoiceHistory(inv)),
            tap(inv => this.invoiceOrig.set(inv)),
            map(inv => ({...inv, comment: ''})),
            tap(inv => this.invoice.set(inv)),
            tap(inv => this.translateService.use(inv.sapLangu.toLowerCase()))
        );
    }

    private processInvoiceTest(): MonoTypeOperatorFunction<InvoiceRecord> {
        return (source) => source.pipe(
            tap(() => this.calculateInvoiceHistoryTest()),
            tap(inv => this.invoiceOrig.set(inv)),
            map(inv => ({...inv, comment: ''})),
            tap(inv => this.invoice.set(inv)),
            tap(inv => this.translateService.use(inv.sapLangu.toLowerCase()))
        );
    }

    getInvoiceTest() {
        this.isLoading.set(true);

        return this.backendService.getInvoiceTest().pipe(
            this.processInvoiceTest(),
            finalize(() => this.isLoading.set(false))
        );
    }

    getInvoiceCollectionTest() {
        this.isLoading.set(true);

        return this.backendService.getInvoiceCollectionTest().pipe(
            tap(invoices => this.invoiceCollection.set(invoices)),
            finalize(() => this.isLoading.set(false))
        );
    }

    getInvoice(invoiceId: string) {
        this.isLoading.set(true);

        return this.backendService.getInvoice(invoiceId).pipe(
            this.processInvoice(),
            finalize(() => this.isLoading.set(false))
        );
    }

    getInvoiceCollection() {
        this.isLoading.set(true);

        return this.backendService.getInvoiceCollection().pipe(
            tap(invoices => this.invoiceCollection.set(invoices)),
            finalize(() => this.isLoading.set(false))
        );
    }

    updateComment(comment: string) {
        const invoice = this.invoice();
        if (invoice) this.invoice.set({...invoice, comment});
    }

    /**
     Updates a specific property of an invoice position based on its sapBuzei identifier.
     @typeparam T - The type of the property to be updated within the InvoicePositionRecord.
     @param {InvoicePositionRecord} updatedPosition - The updated invoice position object containing the new value.
     @param {T} property - The property name of the invoice position to update (must be a key of InvoicePositionRecord).
     @param {InvoicePositionRecord[T]} value - The new value to assign to the specified property.
     @throws {Error} - If the invoice cannot be retrieved.
     @description
     This function updates a specific property within an invoice position based on the provided sapBuzei identifier. It iterates through the existing invoice positions and replaces the matching position with the updated one. Finally, it updates the entire invoice state with the modified positions.
     @example
     const updatedPosition = { sapBuzei: '12345', quantity: 10 };
     updatePosition(updatedPosition, 'quantity', 5);
     */
    updatePosition<T extends keyof InvoicePositionRecord>(updatedPosition: InvoicePositionRecord, property: T, value: InvoicePositionRecord[T]) {
        const invoice = this.invoice();

        if (invoice) {
            const updatedPositions = [...invoice.positions.map((position) => {
                if (position.sapBuzei === updatedPosition.sapBuzei) {
                    updatedPosition[property] = value;
                    return {...updatedPosition} as InvoicePositionRecord;
                }
                return position;
            })];

            this.invoice.set({...invoice, positions: updatedPositions});
        }
    }

    private getAttachmentRequests(invoice: InvoiceRecord) {
        const addAttachmentRequests = this.newAttachments()
        .filter(att => att.file)
        .map(att => this.backendService.addAttachment(invoice.uuid, att.file));

        const removeAttachmentRequests = this.deletedAttachments()
        .map(att => this.backendService.removeAttachment(invoice.uuid, att));

        return [EMPTY, ...addAttachmentRequests, ...removeAttachmentRequests];
    }

    saveChanges(onSuccess: () => void) {
        const invoice = this.invoice();

        if (invoice) {
            this.isLoading.set(true);

            // Ensures saving occurs after all attachments are processed
            concat(this.getAttachmentRequests(invoice)).pipe(
                mergeAll(),
                defaultIfEmpty(null),
                last(), // Ensures we wait for the last emitted value before proceeding
                switchMap(() => this.backendService.saveChanges(invoice)),
                this.processInvoice(),
                tap(() => onSuccess()),
                finalize(() => this.isLoading.set(false))
            ).subscribe();
        }
    }

    saveAndConfirm(onSuccess: () => void, recipient?: string) {
        const invoice = this.invoice();

        if (invoice) {
            this.isLoading.set(true);

            // Ensures saving occurs after all attachments are processed
            concat(this.getAttachmentRequests(invoice)).pipe(
                mergeAll(),
                defaultIfEmpty(null),
                last(), // Ensures we wait for the last emitted value before proceeding
                switchMap(() => this.backendService.saveAndConfirm(invoice, recipient)),
                this.processInvoice(),
                tap(() => onSuccess()),
                finalize(() => this.isLoading.set(false))
            ).subscribe();
        }
    }

    attachFiles(attachments: File[]) {
        const invoice = this.invoice();

        if (invoice) {
            const invoiceAttachments = attachments.map<InvoiceAttachmentRecord>(attachment => {
                return {
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    id: 0,
                    fileName: attachment.name,
                    fileSize: attachment.size,
                    file: attachment
                };
            });

            this.invoice.set({...invoice, attachments: [...invoice.attachments, ...invoiceAttachments]});
        }
    }

    removeAttachmentRecord(attachment: InvoiceAttachmentRecord) {
        const invoice = this.invoice();

        if (invoice) {
            this.invoice.set({...invoice, attachments: [...invoice.attachments.filter(a => a !== attachment)]});
        }
    }

    findCostCenter(searchText: string) {
        const bukrs = this.invoice()?.sapBukrs || '';
        return this.backendService.findCostCenter(bukrs, searchText).pipe();
    }

    findAccount(searchText: string) {
        const language = this.currentLang() === 'en' ? 'E' : 'D';
        return this.backendService.findAccounts(language, searchText).pipe();
    }

    editPosition(position: InvoicePositionRecord) {
        const invoice = this.invoice();

        if (invoice) {
            const positions = invoice.positions.filter((pos) => pos.id !== position.id).concat(position);
            positions.sort((a, b) => a.sapBuzei.localeCompare(b.sapBuzei));
            this.invoice.set({...invoice, positions: positions});
        }
    }

    // TODO: This is currently not used, but will be probably at a later time
//    duplicatePositions(positions: InvoicePositionRecord[]) {
//        this.isLoading.set(true);
//
//        const invoice = this.invoice();
//        const copiedPositions = [...positions];
//
//        if (invoice) {
//            const index = invoice.positions.findIndex((pos) => pos.sapBuzei === copiedPositions[0].sapBuzei);
//
//            const updatedPositions = [...invoice.positions];
//            updatedPositions.splice(index, 1, copiedPositions[0], copiedPositions[1]);
//
//            for (let i = index + 2; i < updatedPositions.length; i++) {
//                const updatedSapBuzei = parseInt(updatedPositions[i].sapBuzei, 10) + 1;
//                updatedPositions[i].sapBuzei = updatedSapBuzei.toString().padStart(3, '0');
//            }
//
//            this.invoice.set({...invoice, positions: updatedPositions});
//            this.isLoading.set(false);
//        }
//    }
}
