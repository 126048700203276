import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatButton, MatIconButton } from '@angular/material/button';
import { InvoiceAttachmentRecord, InvoiceRecord, InvoiceStatus, InvoiceType } from '../../../shared/models/invoice';
import { DecimalPipe } from '@angular/common';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatList, MatListItem } from '@angular/material/list';
import { FileSizePipe } from '../../../shared/pipes/file-size.pipe';
import { ShortenPipe } from '../../../shared/pipes/shorten.pipe';
import { InvoiceService } from '../../../shared/services/invoice.service';
import { FormsModule } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BackendService } from '../../../shared/services/backend.service';
import { saveAs } from 'file-saver';
import { MatTooltip } from '@angular/material/tooltip';
import { LocalizedDatePipe } from '../../../shared/pipes/localized-date.pipe';

@Component({
    selector: 'app-invoice-table',
    standalone: true,
    imports: [
        MatTableModule,
        MatButton,
        TranslateModule,
        MatIcon,
        MatList,
        MatListItem,
        FileSizePipe,
        MatIconButton,
        ShortenPipe,
        DecimalPipe,
        FormsModule,
        MatTooltip,
        LocalizedDatePipe
    ],
    templateUrl: './invoice-table.component.html',
    styleUrl: './invoice-table.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceTableComponent {
    translatePipe = inject(TranslatePipe);
    invoiceService = inject(InvoiceService);
    backendService = inject(BackendService);
    snackBar = inject(MatSnackBar);

    displayedInvoiceColumns = ['supplier', 'creditor', 'sapDocumentNr', 'bukrs', 'invoiceNr', 'invoiceDate', 'netAmount', 'grossAmount', 'currency', 'comment'];
    protected readonly InvoiceStatus = InvoiceStatus;
    invoice = input.required<InvoiceRecord>();

    savedAttachments = this.invoiceService.savedAttachments;
    newAttachments = this.invoiceService.newAttachments;
    deletedAttachments = this.invoiceService.deletedAttachments;

    checkIfInvoiceOrCredit(invoiceText: string, creditText: string) {
        return this.invoice().sapRechngut === InvoiceType.Invoice ? this.translatePipe.transform(invoiceText) : this.translatePipe.transform(creditText);
    }

    openInvoice() {
        window.open(this.invoice().sapUrl, '_blank');
    }

    attachDocuments(event: Event): void {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            const attachments = Array.from(input.files);

            const filteredAttachments = attachments.filter((attachment: File) => {
                if ((attachment.size / 1024) > 10000) {
                    this.snackBar.open(this.translatePipe.transform('toast-attachment-size-warn'), this.translatePipe.transform('close'));
                }
                return (attachment.size / 1024) < 10000;
            });

            this.invoiceService.attachFiles(filteredAttachments);
        }

        // These lines right below will reset the input field so if you removed it you can re-add the same file
        const target = event.target as HTMLInputElement;
        target.value = ''
    }

    downloadAttachment(attachmentRecord: InvoiceAttachmentRecord) {
        const invoice = this.invoice();
        if (invoice) {
            this.backendService.getInvoiceAttachment(invoice.uuid, attachmentRecord.id).subscribe(data => {
                saveAs(data, attachmentRecord.fileName);
            });
        }
    }

    removeAttachment(attachmentRecord: InvoiceAttachmentRecord) {
        this.invoiceService.removeAttachmentRecord(attachmentRecord);
    }

    printPage() {
        window.print();
    }
}
