import {
    CanActivateFn,
    RedirectCommand,
    ResolveFn,
    Router,
    Routes
} from '@angular/router';
import { InvoiceApprovalComponent } from './views/invoice-approval/invoice-approval.component';
import { inject, isDevMode } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { filter, map, of } from 'rxjs';
import { InvoiceRecord } from './shared/models/invoice';
import { InvoiceService } from './shared/services/invoice.service';
import {
    InvoiceCollectionComponent
} from './views/invoice-collection/invoice-collection.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';

export const appAuthenticationGuard: CanActivateFn = (route, state) => {
    const router = inject(Router);
    const authService = inject(AuthService);

    if (isDevMode()) console.log('appAuthenticatedGuard', state.url);

    return toObservable(authService.claims).pipe(filter(Boolean), map(() => {
        // Remove the url query parameters if they are set...
        const urlTree = router.parseUrl(state.url);
        if (urlTree.queryParamMap.keys.length > 0) {
            urlTree.queryParams = {};
            return urlTree;
        }

        return true;
    }));
};

export const appInvoiceResolver: ResolveFn<InvoiceRecord> = (route, state) => {
    const router = inject(Router);
    const invoiceService = inject(InvoiceService);

    if (isDevMode()) console.log('appInvoiceResolver', state.url);

    const invoice$ = environment.mockData ? invoiceService.getInvoiceTest() : invoiceService.getInvoice(route.params['invoiceId']);

    return invoice$.pipe(
        map(invoice => {
            if (invoice) {
                return invoice;
            } else {
                return new RedirectCommand(router.parseUrl('404'));
            }
        }),
        catchError(() => {
            return of(new RedirectCommand(router.parseUrl('404')));
        })
    );
};

export const appInvoiceCollectionResolver: ResolveFn<InvoiceRecord[]> = (route, state) => {
    const router = inject(Router);
    const invoiceService = inject(InvoiceService);

    if (isDevMode()) console.log('appInvoiceCollectionResolver', state.url);

    const invoiceCollection$ = environment.mockData ? invoiceService.getInvoiceCollectionTest() : invoiceService.getInvoiceCollection();

    return invoiceCollection$.pipe(
        map(invoiceCollection => {
            if (invoiceCollection.length > 0) {
                return invoiceCollection;
            } else {
                return new RedirectCommand(router.parseUrl('404'));
            }
        }),
        catchError(() => {
            return of(new RedirectCommand(router.parseUrl('404')));
        })
    );
};

export const ROUTES: Routes = [
    {
        path: 'invoice/:invoiceId',
        component: InvoiceApprovalComponent,
        canActivate: [appAuthenticationGuard],
        resolve: {invoice: appInvoiceResolver}
    },

    {
        path: 'overview',
        component: InvoiceCollectionComponent,
        canActivate: [appAuthenticationGuard],
        resolve: {invoiceCollection: appInvoiceCollectionResolver}
    },

    {path: '404', component: NotFoundComponent},

    // DEFAULT
    {path: '**', redirectTo: 'overview', pathMatch: 'full'}
];
