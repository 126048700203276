<ng-template #viewStyleSelectionTemplate>
    <mat-button-toggle-group name="viewStyle" (change)="changeViewStyle($event.value)" aria-label="View Style">
        <mat-button-toggle value="detailed">
            <mat-icon matButtonIcon>view_list</mat-icon>
            {{ 'overview.detailed-view' | translate }}
        </mat-button-toggle>
        <mat-button-toggle value="compact" checked>
            <mat-icon matButtonIcon>list</mat-icon>
            {{ 'overview.compact-view' | translate }}
        </mat-button-toggle>
    </mat-button-toggle-group>
</ng-template>

@if (isSmallScreen()) {
    <div class="button-row centered">
        <ng-container *ngTemplateOutlet="viewStyleSelectionTemplate"></ng-container>
    </div>
}

<div class="button-row stretched">
    <mat-checkbox [(ngModel)]="showClosedInvoices">{{ 'overview.show-closed-invoices' | translate }}</mat-checkbox>

    @if (!isSmallScreen()) {
        <ng-container *ngTemplateOutlet="viewStyleSelectionTemplate"></ng-container>
    }

    <button mat-stroked-button (click)="clear(overviewTable)">{{ 'overview.clear-filters' | translate }}</button>
</div>

<!--@if (viewStyle() === 'detailed') {-->
<!--    @for (invoice of invoices(); track $index) {-->
<!--        <app-overview-invoice-card [invoice]="invoice"/>-->
<!--        @if (!$last) {-->
<!--            <mat-divider/>-->
<!--        }-->
<!--    }-->

<!--} @else {-->
<!--    <app-overview-invoice-table [invoices]="invoices()" />-->
<!--}-->
<!--<button mat-icon-button>-->
<!--    <mat-icon>filter_alt</mat-icon>-->
<!--</button>-->

<!--@for (filter of filters(); track $index) {-->
<!--    <mat-form-field appearance="outline">-->
<!--        <mat-label>{{ filter.name }}</mat-label>-->
<!--        <input type="text" matInput [ngModel]="filter.value" (ngModelChange)="changeFilter(filter, $event)">-->
<!--    </mat-form-field>-->
<!--}-->

<section [style]="{height: 'calc(100% - 100px)'}">
    <p-table
        #overviewTable
        class="custom-table mat-elevation-z2"
        styleClass="p-datatable-striped"
        sortMode="multiple"
        [value]="filteredInvoices()"
        [scrollable]="true"
        [scrollHeight]="'flex'"
    >
        <!--    <ng-template pTemplate="caption">-->
        <!--        <div>-->
        <!--            <p-button label="Clear" [outlined]="true" icon="pi pi-filter-slash" (onClick)="clear(dt1)"/>-->
        <!--                        <span class="p-input-icon-left ml-auto">-->
        <!--                            <i class="pi pi-search"></i>-->
        <!--                            <input pInputText type="text" [(ngModel)]="searchValue" (input)="dt1.filterGlobal($event.target.value || '', 'contains')" placeholder="Search keyword" />-->
        <!--                        </span>-->
        <!--        </div>-->
        <!--    </ng-template>-->

        <ng-template pTemplate="header">
            <tr>
                @if (viewStyle() === 'compact') {
                    <!--                <th pSortableColumn="status">-->
                        <!--                    <div>-->
                        <!--                        {{ 'status' | translate }}-->
                        <!--                        <p-sortIcon field="status"/>-->
                        <!--                        <p-columnFilter type="text" field="status" display="menu" matchMode="in" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">-->
                        <!--                            <ng-template pTemplate="filter" let-selectedStatuses let-filter="filterCallback">-->
                        <!--                                <p-multiSelect [ngModel]="selectedStatuses" [options]="[0,1,2,3,4,5]" placeholder="Any" (onChange)="filter($event.value)">-->
                        <!--                                    <ng-template pTemplate="selectedItems">-->
                        <!--                                        @if (!selectedStatuses || selectedStatuses.length === 0) {-->
                        <!--                                            <div>{{'primeng.selectionMessage' | translate}}</div>-->
                        <!--                                        } @else if (selectedStatuses.length > 3) {-->
                        <!--                                            {{ selectedStatuses.length }} items selected-->
                        <!--                                        } @else {-->
                        <!--                                            @for (selectedStatus of selectedStatuses; track $index) {-->
                        <!--                                                <span>{{ statusText(selectedStatus) | translate }}</span>-->
                        <!--                                                @if (!$last) {-->
                        <!--                                                    <span>, </span>-->
                        <!--                                                }-->
                        <!--                                            }-->
                        <!--                                        }-->
                        <!--                                    </ng-template>-->

                        <!--                                    <ng-template let-option pTemplate="item">-->
                        <!--                                        <div>-->
                        <!--                                            <span>{{ statusText(option) | translate }}</span>-->
                        <!--                                        </div>-->
                        <!--                                    </ng-template>-->
                        <!--                                </p-multiSelect>-->
                        <!--                            </ng-template>-->
                        <!--                        </p-columnFilter>-->
                        <!--                    </div>-->
                        <!--                </th>-->
                    <th pSortableColumn="status">
                        <div>
                            {{ 'status' | translate }}
                            <p-sortIcon field="status"/>
                        </div>
                    </th>
                }

                <th pSortableColumn="sapLifnam">
                    <div>
                        {{ 'supplier' | translate }}
                        <p-sortIcon field="sapLifnam"/>
                        <p-columnFilter type="text" field="sapLifnam" display="menu"/>
                    </div>
                </th>

                <th pSortableColumn="sapLifnr">
                    <div>
                        {{ 'creditor' | translate }}
                        <p-sortIcon field="sapLifnr"/>
                        <p-columnFilter type="text" field="sapLifnr" display="menu"/>
                    </div>
                </th>

                <th pSortableColumn="sapBelnr">
                    <div>
                        {{ 'sap-receipt' | translate }}
                        <p-sortIcon field="sapBelnr"/>
                        <p-columnFilter type="text" field="sapBelnr" display="menu"/>
                    </div>
                </th>

                <th pSortableColumn="sapBukrs">
                    <div>
                        {{ 'sap-bukrs' | translate }}
                        <p-sortIcon field="sapBukrs"/>
                        <p-columnFilter type="text" field="sapBukrs" display="menu"/>
                    </div>
                </th>

                <th pSortableColumn="sapRechdat" class="align-right">
                    <div>
                        {{ 'invoice-date' | translate }}
                        <p-sortIcon field="sapRechdat"/>
                        <p-columnFilter type="date" field="sapRechdat" display="menu"/>
                    </div>
                </th>

                <th pSortableColumn="sapXblnr">
                    <div>
                        {{ 'invoice-nr' | translate }}
                        <p-sortIcon field="sapXblnr"/>
                        <p-columnFilter type="text" field="sapXblnr" display="menu"/>
                    </div>
                </th>

                <th pSortableColumn="sapNetto" class="align-right">
                    <div>
                        {{ 'net-amount' | translate }}
                        <p-sortIcon field="sapNetto"/>
                        <p-columnFilter type="numeric" field="sapNetto" display="menu"/>
                    </div>
                </th>

                @if (viewStyle() === 'detailed') {
                    <th pSortableColumn="sapBrutto" class="align-right">
                        <div>
                            {{ 'gross-amount' | translate }}
                            <p-sortIcon field="sapBrutto"/>
                            <p-columnFilter type="numeric" field="sapBrutto" display="menu"/>
                        </div>
                    </th>
                }

                <th pSortableColumn="sapWaers">
                    <div>
                        {{ 'currency' | translate }}
                        <p-sortIcon field="sapWaers"/>
                        <p-columnFilter type="text" field="sapWaers" display="menu"/>
                    </div>
                </th>

                @if (viewStyle() === 'detailed') {
                    <th pSortableColumn="sapBruttoeur" class="align-right">
                        <div>
                            {{ 'local-amount' | translate }}
                            <p-sortIcon field="sapBruttoeur"/>
                            <p-columnFilter type="numeric" field="sapBruttoeur" display="menu"/>
                        </div>
                    </th>

                    <th pSortableColumn="sapSkomment">
                        <div>
                            {{ 'note' | translate }}
                            <p-sortIcon field="sapSkomment"/>
                            <p-columnFilter type="text" field="sapSkomment" display="menu"/>
                        </div>
                    </th>
                }

                @if (viewStyle() === 'compact') {
                    <th>{{ 'details' | translate }}</th>
                }
            </tr>
        </ng-template>

        <!--    <ng-template pTemplate="groupheader" let-invoice>-->
        <!--        <tr pRowGroupHeader>-->
        <!--            <td colspan="10">-->
        <!--                <p class="section-title">Invoice</p>-->
        <!--            </td>-->
        <!--        </tr>-->
        <!--    </ng-template>-->

        <ng-template pTemplate="body" let-invoice>
            @if (viewStyle() === 'detailed') {
                <tr>
                    <td colspan="11">
                        <p class="section-title">{{ checkIfInvoiceOrCredit(invoice, 'invoice', 'credit') }}</p>
                    </td>
                </tr>
                <tr>
                    <td>{{ invoice.sapLifnam }}</td>
                    <td>{{ invoice.sapLifnr }}</td>
                    <td>{{ invoice.sapBelnr }}</td>
                    <td>{{ invoice.sapBukrs }}</td>
                    <td class="align-right">{{ invoice.sapRechdat | localizedDate }}</td>
                    <td>{{ invoice.sapXblnr }}</td>
                    <td class="align-right">{{ invoice.sapNetto | number:'1.2-2' }}</td>
                    <td class="align-right">{{ invoice.sapBrutto | number:'1.2-2' }}</td>
                    <td>{{ invoice.sapWaers }}</td>
                    <td class="align-right">{{ invoice.sapBruttoeur | number:'1.2-2' }}</td>
                    <td>{{ invoice.sapSkomment }}</td>
                </tr>
                <tr>
                    <td colspan="11" [style.padding]="0">
                        <div class="position-state main-state" [ngClass]="getStatusClass(invoice)">
                            <div class="approve-reject">
                                <div class="text-bold">{{ getStatusText(invoice) | translate }}</div>
                            </div>
                            <span class="text date">
                                @if (invoice.changedByEmail !== '') {
                                    {{ invoice.changedByForename + ' ' + invoice.changedBySurname }},
                                }
                                {{ invoice.updatedAt | localizedDate }}
                        </span>
                            <button mat-button mat-flat-button (click)="navigate(invoice)">{{ 'details' | translate }}</button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="11">

                    </td>
                </tr>
            } @else {
                <tr [ngClass]="getStatusClass(invoice)">
                    <td class="main-state">{{ getStatusText(invoice) | translate }}</td>
                    <td>{{ invoice.sapLifnam }}</td>
                    <td>{{ invoice.sapLifnr }}</td>
                    <td>{{ invoice.sapBelnr }}</td>
                    <td>{{ invoice.sapBukrs }}</td>
                    <td class="align-right">{{ invoice.sapRechdat | localizedDate }}</td>
                    <td>{{ invoice.sapXblnr }}</td>
                    <td class="align-right">{{ invoice.sapNetto | number: '1.2-2' }}</td>
                    <td>{{ invoice.sapWaers }}</td>
                    <td>
                        <button mat-button mat-flat-button (click)="navigate(invoice)">{{ checkIfInvoiceOrCredit(invoice, 'invoice', 'credit') }}</button>
                    </td>
                </tr>
            }
        </ng-template>
    </p-table>
</section>
