import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatList, MatListItem } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';
import { MailNamePipe } from '../../../shared/pipes/mail-name.pipe';
import { AuthService } from '../../../shared/services/auth.service';

@Component({
    selector: 'app-overview-address',
    standalone: true,
    imports: [
        DatePipe,
        MailNamePipe,
        MatList,
        MatListItem,
        TranslateModule
    ],
    templateUrl: './overview-address.component.html',
    styleUrl: './overview-address.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewAddressComponent {
    authService = inject(AuthService);

    claims = this.authService.claims;
}
