import { inject, Pipe, PipeTransform } from '@angular/core';
import { LocalizedDatePipe } from './localized-date.pipe';
import { CapitalizeFirstLetterPipe } from './capitalize-first-letter.pipe';

interface ChangeInformation {
    changedByForename: string;
    changedBySurname: string;
    updatedAt: Date;
}

@Pipe({
    name: 'changedBy',
    standalone: true,
    pure: false
})
export class ChangedByPipe implements PipeTransform {
    private localizedDatePipe = inject(LocalizedDatePipe);
    private capitalizeFirstLetterPipe = inject(CapitalizeFirstLetterPipe);

    transform<T extends ChangeInformation>(value: T): string {
        const changeForename = this.capitalizeFirstLetterPipe.transform(value.changedByForename);
        const changeSurname = this.capitalizeFirstLetterPipe.transform(value.changedBySurname);
        const changeDate = this.localizedDatePipe.transform(value.updatedAt, 'shortDate');
        const changeTime = this.localizedDatePipe.transform(value.updatedAt, 'shortTime')

        return `${changeForename} ${changeSurname}, ${changeDate} ${changeTime}`;
    }
}
