<h2 mat-dialog-title>{{ 'reject-invoice-dialog.title' | translate }}</h2>

<mat-dialog-content>
    <form [formGroup]="rejectionForm">
        <mat-form-field appearance="outline">
            <mat-label>{{ 'reject-invoice-dialog.label' | translate }}</mat-label>
            <input formControlName="comment" type="text" matInput>
        </mat-form-field>
        @if (comment.hasError('required')) {
            <mat-error>
                {{ 'reject-invoice-dialog.error' | translate }}
            </mat-error>
        }
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-flat-button (click)="dialogRef.close()">{{ 'close' | translate }}</button>
    <button mat-flat-button (click)="proceed()">{{ 'OK' | translate }}</button>
</mat-dialog-actions>
