import { inject } from '@angular/core';
import {
    HttpRequest,
    HttpErrorResponse,
    HttpStatusCode, HttpInterceptorFn, HttpHandlerFn
} from '@angular/common/http';
import { switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../services/auth.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

interface ErrorResponse {
    status: string;
    httpCode: number;
    message: string;
    reason: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isErrorResponse = (obj: any): obj is ErrorResponse => {
    return typeof obj === 'object' &&
        typeof obj.status === 'string' &&
        typeof obj.httpCode === 'number' &&
        typeof obj.message === 'string' &&
        typeof obj.reason === 'string';
}

export const httpErrorInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
    const authService = inject(AuthService);
    const snackBar = inject(MatSnackBar);

    return next(req).pipe(
        catchError((error: HttpErrorResponse) => {
            let errorMessage = '';

            if (error.error instanceof ErrorEvent) {
                // Client-side error
                errorMessage = `${error.error.message}`;
            } else if (isErrorResponse(error.error)) {
                // Server-side error
                errorMessage = `${error.error.message}: ${error.error.reason}`;
            } else {
                // Server-side error
                errorMessage = `Server-side error: ${error.status} ${error.message}`;
            }

            // Log the error (you can also use a logging service here)
            console.error(errorMessage);

            if (error.status === HttpStatusCode.Unauthorized) {
               return fromPromise(authService.logout()).pipe(
                   switchMap(() => throwError(() => new Error(errorMessage)))
               );
            } else {
                // Show a user-friendly message to the user
                snackBar.open(errorMessage, 'Close', {
                    duration: 10000,
                    panelClass: 'error'
                });

                return throwError(() => new Error(errorMessage));
            }
        })
    );
};
