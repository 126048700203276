import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import {
    InvoicePositionTableComponent
} from './invoice-position-table/invoice-position-table.component';
import { MatButton } from '@angular/material/button';
import { InvoiceRecord, InvoiceStatus } from '../../../shared/models/invoice';
import { TranslateModule } from '@ngx-translate/core';
import { MatDivider } from '@angular/material/divider';
import { InvoiceService } from '../../../shared/services/invoice.service';

@Component({
    selector: 'app-accounting-information',
    standalone: true,
    imports: [
        InvoicePositionTableComponent,
        MatButton,
        TranslateModule,
        MatDivider
    ],
    templateUrl: './accounting-information.component.html',
    styleUrl: './accounting-information.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountingInformationComponent {
    invoiceService = inject(InvoiceService);

    invoice = input.required<InvoiceRecord>();
    isReadOnly = this.invoiceService.isReadOnly;

    protected readonly InvoiceStatus = InvoiceStatus;

    setAllPositionsFactualRight() {
        this.invoice().positions.forEach(position => {
            this.invoiceService.updatePosition(position, 'factualRight', true)
        });
    }

    setAllPositionsPriceRight() {
        this.invoice().positions.forEach(position => {
            this.invoiceService.updatePosition(position, 'priceRight', true)
        });
    }
}
