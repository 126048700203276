import { inject, Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {
    private breakpointObserver = inject(BreakpointObserver);

    isSmallScreen = toSignal(this.breakpointObserver.observe(['(max-width: 959px)']).pipe(map(state => state.matches)), {initialValue: false});
}
