import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { InvoiceRecord } from '../../../../shared/models/invoice';

@Component({
    selector: 'app-reject-invoice-dialog',
    standalone: true,
    imports: [
        MatDialogModule,
        MatFormFieldModule,
        MatButton,
        MatInput,
        TranslateModule,
        ReactiveFormsModule
    ],
    templateUrl: './reject-invoice-dialog.component.html',
    styleUrl: './reject-invoice-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RejectInvoiceDialogComponent {
    dialogRef = inject(MatDialogRef<InvoiceRecord, string>);
    invoice = inject(MAT_DIALOG_DATA) as InvoiceRecord;

    rejectionForm = new FormGroup({
        comment: new FormControl(this.invoice.comment, {
            validators: [
                Validators.required
            ],
            nonNullable: true
        })
    });

    get comment() {
        return this.rejectionForm.controls.comment;
    }

    proceed() {
        if (this.rejectionForm.valid && this.rejectionForm.value !== null) {
            this.dialogRef.close(this.comment.value);
        }
    }
}
