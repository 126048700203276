import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import {
    MatTableModule
} from '@angular/material/table';
import { InvoicePositionHistoryComponent } from './invoice-position-history/invoice-position-history.component';
import { InvoicePositionRecord, InvoiceRecord, InvoiceStatus } from '../../../../shared/models/invoice';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { InvoiceService } from '../../../../shared/services/invoice.service';
import { FormsModule } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs';
import { EditPositionDialogComponent } from '../../dialogs/edit-position-dialog/edit-position-dialog.component';

@Component({
    selector: 'app-invoice-position-table',
    standalone: true,
    imports: [
        MatTableModule,
        MatSlideToggleModule,
        TranslateModule,
        InvoicePositionHistoryComponent,
        FormsModule,
        DecimalPipe,
        MatIconButton,
        MatIcon,
        MatTooltip
    ],
    templateUrl: './invoice-position-table.component.html',
    styleUrl: './invoice-position-table.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoicePositionTableComponent {
    invoiceService = inject(InvoiceService);
    translatePipe = inject(TranslatePipe);
    dialog = inject(MatDialog);

    invoice = input.required<InvoiceRecord>();
    invoiceStatus = input.required<InvoiceStatus>();
    position = input.required<InvoicePositionRecord>();
    positionLocked = computed(() => this.position().sapPossperr !== '');

    isReadOnly = this.invoiceService.isReadOnly;
    currentLang = this.invoiceService.currentLang;

    sapSaknrtextTranslated = computed(() => {
        return this.currentLang() === 'en' ? this.position().sapSaknrtextE : this.position().sapSaknrtextD;
    });

    protected readonly InvoiceStatus = InvoiceStatus;
    displayedPositionColumns: string[] = ['positionNr', 'text', 'netAmount', 'pspElement', 'order', 'costCenter', 'account'];

    editPosition() {
        const dialog = this.dialog.open<EditPositionDialogComponent, InvoicePositionRecord, InvoicePositionRecord | null>(EditPositionDialogComponent, {
            width: '800px',
            maxHeight: '800px',
            hasBackdrop: true,
            data: this.position(),
            autoFocus: false
        });

        dialog.afterClosed().pipe(
            tap(editedPosition => {
                if (editedPosition) {
                    this.invoiceService.editPosition(editedPosition);
                }
            })
        ).subscribe();
    }

// TODO: This is currently not used, but will be probably at a later time
    /*copyPosition() {
        const dialog = this.dialog.open<CopyPositionDialogComponent, InvoicePositionRecord, InvoicePositionRecord[]>(CopyPositionDialogComponent, {
            width: '800px',
            maxHeight: '800px',
            hasBackdrop: true,
            data: this.position()
        });

        dialog.afterClosed().pipe(
            tap((positions) => {
                if (positions) {
                    this.invoiceService.duplicatePositions(positions);
                }
            })
        ).subscribe();
    }

    mergePositions() {
        const dialog = this.dialog.open<MergePositionDialogComponent, InvoicePositionRecord, InvoicePositionRecord>(MergePositionDialogComponent, {
            width: '800px',
            maxHeight: '800px',
            hasBackdrop: true,
            data: this.position()
        });

        dialog.afterClosed().pipe(
            tap((positions) => {
                if (positions) {

                }
            })
        ).subscribe();
    }*/
}
