import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { InvoicePositionRecord, InvoiceStatus } from '../../../../../shared/models/invoice';
import { TranslateModule } from '@ngx-translate/core';
import { JsonPipe } from '@angular/common';
import { InvoicePositionChange } from '../../../../../shared/models/invoice-history';
import { ChangedByPipe } from '../../../../../shared/pipes/changed-by.pipe';
import { InvoiceService } from '../../../../../shared/services/invoice.service';
import { LocalizedDatePipe } from '../../../../../shared/pipes/localized-date.pipe';

@Component({
    selector: 'app-invoice-position-history',
    standalone: true,
    imports: [
        TranslateModule,
        LocalizedDatePipe,
        ChangedByPipe,
        JsonPipe
    ],
    templateUrl: './invoice-position-history.component.html',
    styleUrl: './invoice-position-history.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoicePositionHistoryComponent {
    invoiceService = inject(InvoiceService);
    position = input.required<InvoicePositionRecord>();
    invoiceStatus = input.required<InvoiceStatus>();
    invoiceChanges = this.invoiceService.invoiceChanges;

    protected readonly InvoiceStatus = InvoiceStatus;

    positionChanges = computed(() => {
        const position = this.position();
        const invoiceChanges = this.invoiceChanges();

        return invoiceChanges.flatMap(invoiceChange => invoiceChange.positions.filter(positionChange => positionChange.id === position.id));
    });

    getFactualRightChanges = computed(() => {
        const factualRightChanges: InvoicePositionChange[] = [];
        let lastPositionChange: InvoicePositionChange;

        this.positionChanges().forEach(positionChange => {
            if (lastPositionChange && lastPositionChange.factualRight !== positionChange.factualRight) {
                factualRightChanges.push(positionChange);
            }

            lastPositionChange = positionChange;
        });

        return factualRightChanges;
    });

    getPriceRightChanges = computed(() => {
        const priceRightChanges: InvoicePositionChange[] = [];
        let lastPositionChange: InvoicePositionChange;

        this.positionChanges().forEach(positionChange => {
            if (lastPositionChange && lastPositionChange.priceRight !== positionChange.priceRight) {
                priceRightChanges.push(positionChange);
            }

            lastPositionChange = positionChange;
        });

        return priceRightChanges;
    });
}
