import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { InvoiceRecord, InvoiceStatus, InvoiceType } from '../../../shared/models/invoice';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-document-information',
    standalone: true,
    imports: [
        MatIcon,
        TranslateModule,
        NgClass
    ],
    templateUrl: './document-information.component.html',
    styleUrl: './document-information.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentInformationComponent {
    invoice = input.required<InvoiceRecord>();
    protected readonly InvoiceStatus = InvoiceStatus;
    protected readonly InvoiceType = InvoiceType;
}
