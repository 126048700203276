<div class="group">
    @if (invoiceStatus() > InvoiceStatus.Open) {
        @for (factualRightChange of getFactualRightChanges(); track factualRightChange) {
            @if ($last) {
                @if (factualRightChange.factualRight) {
                    <div class="position-state reason-state approved">
                        <div class="approve-reject">
                            <div class="text-bold">{{ "position.factual-right" | translate }}</div>
                        </div>
                        <span class="text date">{{ factualRightChange | changedBy }}</span>
                    </div>
                } @else {
                    <div class="position-state reason-state rejected">
                        <div class="approve-reject">
                            <div class="text-bold">{{ "position.factual-wrong" | translate }}</div>
                        </div>
                        <span class="text date">{{ factualRightChange | changedBy }}</span>
                    </div>
                }
            }
        }
    }

    @if (invoiceStatus() > InvoiceStatus.WaitingForApproval) {
        @for (priceRightChange of getPriceRightChanges(); track priceRightChange) {
            @if ($last) {
                @if (priceRightChange.priceRight) {
                    <div class="position-state reason-state approved">
                        <div class="approve-reject">
                            <div class="text-bold">{{ "position.price-right" | translate }}</div>
                        </div>
                        <span class="text date">{{ priceRightChange | changedBy }}</span>
                    </div>
                } @else {
                    <div class="position-state reason-state rejected">
                        <div class="approve-reject">
                            <div class="text-bold">{{ "position.price-wrong" | translate }}</div>
                        </div>
                        <span class="text date">{{ priceRightChange | changedBy }}</span>
                    </div>
                }
            }
        }
    }
</div>
