import { DatePipe } from '@angular/common';
import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'localizedDate',
    standalone: true,
    pure: false
})
export class LocalizedDatePipe implements PipeTransform {
    private translateService = inject(TranslateService);

    transform(value: string | number | Date, pattern = 'shortDate'): string | null {
        const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);
        return datePipe.transform(value, pattern);
    }
}
