<p class="section-title">{{ "add-comment" | translate }}</p>

@if (commentChanges().length > 0) {
    <mat-card>
        <mat-card-content>
            @for (commentChange of commentChanges(); track $index) {
                <div>
                    <mat-card-title class="text-bold">{{ commentChange | changedBy | capitalizeFirstLetterPipe }}
                    </mat-card-title>
                    <p style="padding: 0 10px" class="text">{{ commentChange.comment }}</p>
                </div>
            }
        </mat-card-content>
    </mat-card>
}

<form [formGroup]="formGroup">
    <mat-form-field appearance="outline" [style.display]="invoice().status === InvoiceStatus.Open || invoice().status === InvoiceStatus.WaitingForApproval ? '' : 'none'">
        <mat-label>{{ "comment-placeholder" | translate }}</mat-label>
        <textarea formControlName="invoiceComment" matInput (input)="updateComment()"></textarea>
        @if (invoiceComment.hasError('maxlength')) {
            <mat-error>
                {{ 'comment-length-error' | translate }}
            </mat-error>
        }
    </mat-form-field>
</form>
