<div [class.approved]="invoice().status === InvoiceStatus.Approved || invoice().status === InvoiceStatus.PartiallyApproved"
     [class.rejected]="invoice().status === InvoiceStatus.Rejected">
    <mat-icon>info</mat-icon>
    <div class="info-text">
        @if (invoice().sapRechngut === InvoiceType.Invoice) {
            @if (invoice().status === InvoiceStatus.Open || invoice().status === InvoiceStatus.WaitingForApproval) {
                <p class="text-medium">{{ "information.invoice-text" | translate }}</p>
            }
            @else if (invoice().status === InvoiceStatus.Approved) {
                <p class="section-title">{{ "information.invoice-approved" | translate }}</p>
            }
            @else if (invoice().status === InvoiceStatus.Rejected) {
                <p class="section-title">{{ "information.invoice-rejected" | translate }}</p>
            }
            @else if (invoice().status === InvoiceStatus.PartiallyApproved) {
                <p class="section-title">{{ "information.invoice-partially-approved" | translate }}</p>
            }
            @else if (invoice().status === InvoiceStatus.Closed) {
                <p class="section-title">{{ "information.invoice-closed" | translate }}</p>
            }
        } @else {
            @if (invoice().status === InvoiceStatus.Open || invoice().status === InvoiceStatus.WaitingForApproval) {
                <p class="text-medium">{{ "information.credit-text" | translate }}</p>
            }
            @else if (invoice().status === InvoiceStatus.Approved) {
                <p class="section-title">{{ "information.credit-approved" | translate }}</p>
            }
            @else if (invoice().status === InvoiceStatus.Rejected) {
                <p class="section-title">{{ "information.credit-rejected" | translate }}</p>
            }
            @else if (invoice().status === InvoiceStatus.PartiallyApproved) {
                <p class="section-title">{{ "information.credit-partly" | translate }}</p>
            }
            @else if (invoice().status === InvoiceStatus.Closed) {
                <p class="section-title">{{ "information.invoice-closed" | translate }}</p>
            }
        }
    </div>
</div>
