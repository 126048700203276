import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatList, MatListItem } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';
import { InvoiceService } from '../../../shared/services/invoice.service';

@Component({
    selector: 'app-overview-address',
    standalone: true,
    imports: [
        MatList,
        MatListItem,
        TranslateModule
    ],
    templateUrl: './overview-address.component.html',
    styleUrl: './overview-address.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewAddressComponent {
    invoiceService = inject(InvoiceService);

    userinfo = this.invoiceService.userinfo;
}
