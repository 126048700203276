<div class="left-side">
    <div class="image-container">
        <img ngSrc="assets/lsg_logo.svg" alt="LSG Logo" priority fill>
    </div>

    <div>
        @switch (invoice()?.sapRechngut) {
            @case (InvoiceType.Invoice) {
                @if (checkForPositionLock()) {
                    <p class="title">{{ 'toolbar.partly' | translate }}</p>
                } @else {
                    <p class="title">{{ 'toolbar.approval' | translate }}</p>
                }
            }
            @case (InvoiceType.Credit) {
                <p class="title">{{ 'toolbar.credit' | translate }}</p>
            }
        }
        @if (!invoice() && !isError()) {
            <p class="title">{{ 'toolbar.invoice-overview' | translate }}</p>
        }
    </div>
</div>

<div class="right-side">
    @if (!isSmallScreen()) {
        @if (currentLang() === 'de') {
            <button mat-button class="secondary" (click)="changeLocale('en')">
                <mat-icon>language</mat-icon>
                English
            </button>
        } @else {
            <button mat-button class="secondary" (click)="changeLocale('de')">
                <mat-icon>language</mat-icon>
                Deutsch
            </button>
        }

        <button mat-button class="secondary" (click)="openHelp()">
            <mat-icon>help</mat-icon>
            {{ 'toolbar.help' | translate }}
        </button>
        <button mat-button class="secondary logout-button" (click)="logout()">
            <mat-icon>logout</mat-icon>
            {{ 'toolbar.sign-out' | translate }}
            ({{ claims()?.name || 'Unknown' | capitalizeFirstLetterPipe }})
        </button>
    } @else if (isSmallScreen()) {
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            @if (currentLang() === 'de') {
                <button mat-menu-item class="secondary" (click)="changeLocale('en')">
                    <mat-icon>language</mat-icon>
                    English
                </button>
            } @else {
                <button mat-menu-item class="secondary" (click)="changeLocale('de')">
                    <mat-icon>language</mat-icon>
                    Deutsch
                </button>
            }
            <button mat-menu-item (click)="openHelp()" class="help-button secondary">
                <mat-icon>help</mat-icon>
                {{ 'toolbar.help' | translate }}
            </button>
            <button mat-menu-item class="logout-button secondary" (click)="logout()">
                <mat-icon>logout</mat-icon>
                {{ 'toolbar.sign-out' | translate }}
                ({{ claims()?.name || '???' | capitalizeFirstLetterPipe }})
            </button>
        </mat-menu>
    }
</div>
