import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatList, MatListItem } from '@angular/material/list';
import { InvoiceTableComponent } from './invoice-table/invoice-table.component';
import { AccountingInformationComponent } from './accounting-information/accounting-information.component';
import { MatButton } from '@angular/material/button';
import { InvoiceService } from '../../shared/services/invoice.service';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { InvoiceCommentComponent } from './invoice-comment/invoice-comment.component';
import { MailNamePipe } from '../../shared/pipes/mail-name.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { DocumentInformationComponent } from './document-information/document-information.component';
import { ToolbarComponent } from '../../shared/components/toolbar/toolbar.component';
import { InvoiceAddressComponent } from './invoice-address/invoice-address.component';
import { InvoiceRecord, InvoiceStatus } from '../../shared/models/invoice';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatDialog } from '@angular/material/dialog';
import { EmailDialogComponent } from './dialogs/email-dialog/email-dialog.component';
import { RejectInvoiceDialogComponent } from './dialogs/reject-invoice-dialog/reject-invoice-dialog.component';
import { LocalizedDatePipe } from '../../shared/pipes/localized-date.pipe';

@Component({
    selector: 'app-invoice-approval',
    standalone: true,
    imports: [
        MatList,
        MatListItem,
        InvoiceTableComponent,
        AccountingInformationComponent,
        MatButton,
        LocalizedDatePipe,
        MatFormField,
        MatInput,
        InvoiceCommentComponent,
        MailNamePipe,
        TranslateModule,
        DocumentInformationComponent,
        ToolbarComponent,
        InvoiceAddressComponent,
        MatProgressSpinner,
        MatProgressBar
    ],
    templateUrl: './invoice-approval.component.html',
    styleUrl: './invoice-approval.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceApprovalComponent {
    private invoiceService = inject(InvoiceService);
    private dialog = inject(MatDialog);

    protected readonly InvoiceStatus = InvoiceStatus;

    invoice = this.invoiceService.invoice;
    isLoading = this.invoiceService.isLoading;
    isReadOnly = this.invoiceService.isReadOnly;
    isInvoiceCorrect = this.invoiceService.isInvoiceCorrect;

    saveChanges() {
        this.invoiceService.saveChanges();
    }

    finalizeInvoice() {
        const invoice = this.invoice();
        if (!invoice) return;

        // Check if the invoice is going to be approved or rejected
        if (this.isInvoiceCorrect()) {
            // Confirmation of invoices in status open will automatically email the cost center responsible
            if (invoice.status === InvoiceStatus.Open) {
                this.invoiceService.saveAndConfirm(invoice.sapEmpfangk);
            } else {
                this.invoiceService.saveAndConfirm();
            }
        } else {
            this.openRejectionDialog().subscribe(comment => {
                if (comment && comment.length > 0) {
                    this.invoiceService.updateComment(comment);
                    this.invoiceService.saveAndConfirm();
                }
            });
        }
    }

    openRejectionDialog() {
        const dialog = this.dialog.open<RejectInvoiceDialogComponent, InvoiceRecord, string>(RejectInvoiceDialogComponent, {
            width: '600px',
            maxHeight: '800px',
            hasBackdrop: true,
            data: this.invoice()
        });

        return dialog.afterClosed();
    }

    openEmailDialog() {
        const dialog = this.dialog.open<EmailDialogComponent, InvoiceRecord, string>(EmailDialogComponent, {
            width: '600px',
            maxHeight: '800px',
            hasBackdrop: true,
            data: this.invoice()
        });

        return dialog.afterClosed();
    }

    getStyle(status: InvoiceStatus, style: string): string {
        return status === InvoiceStatus.Open || status === InvoiceStatus.WaitingForApproval ? '' : style;
    }
}
