[
  {
    "status": 0,
    "comment": "",
    "changedBy": "SERVICE",
    "changedByEmail": "",
    "changedByForename": "",
    "changedBySurname": "",
    "createdAt": "2024-08-01T15:56:28Z",
    "updatedAt": "2024-08-01T15:56:57Z",
    "positions": [
      {
        "invoicePositionId": 25,
        "factualRight": false,
        "priceRight": false,
        "createdAt": "2024-08-01T15:56:28Z",
        "updatedAt": "2024-08-01T15:56:28Z"
      },
      {
        "invoicePositionId": 26,
        "factualRight": false,
        "priceRight": false,
        "createdAt": "2024-08-01T15:56:28Z",
        "updatedAt": "2024-08-01T15:56:28Z"
      }
    ],
    "attachments": []
  }
]
