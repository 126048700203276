import { inject, Pipe, PipeTransform } from '@angular/core';
import { CapitalizeFirstLetterPipe } from './capitalize-first-letter.pipe';

@Pipe({
    name: 'mailToName',
    standalone: true
})
export class MailToNamePipe implements PipeTransform {
    private capitalizeFirstLetterPipe = inject(CapitalizeFirstLetterPipe);

    transform(email: string): string {
        const namePart = email.split('@')[0];
        return this.capitalizeFirstLetterPipe.transform(namePart.replace(/[._-]/g, ' ').toLowerCase());
    }
}
