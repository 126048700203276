import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, of } from 'rxjs';
import { InvoiceAttachmentRecord, InvoiceRecord } from '../models/invoice';
import { InvoiceHistoryRecord } from '../models/invoice-history';
import accounts from '../mock-data/accounts.json';
import costCenters from '../mock-data/cost-centers.json';
import invoiceData from '../mock-data/invoice.json';
import invoiceCollection from '../mock-data/invoice-collection.json'
import invoiceHistory from '../mock-data/invoice-history.json'
import { environment } from '../../../environments/environment';
import { CostCenterFindRequestData, CostCenterRecord } from '../models/cost-center';
import { AccountFindRequestData, AccountRecord } from '../models/account';

const REST_URL = environment.apiUrl;

@Injectable({
    providedIn: 'root'
})
export class BackendService {
    http = inject(HttpClient);

    findCostCenterTest() {
        return of(JSON.stringify(costCenters)).pipe(
            map(response => parseJsonWithDates<CostCenterRecord[]>(response))
        );
    }

    findAccountTest() {
        return of(JSON.stringify(accounts)).pipe(
            map(response => parseJsonWithDates<AccountRecord[]>(response))
        );
    }

    getInvoiceTest() {
        return of(JSON.stringify(invoiceData)).pipe(
            map(response => parseJsonWithDates<InvoiceRecord>(response))
        );
    }

    getInvoiceCollectionTest() {
        return of(JSON.stringify(invoiceCollection)).pipe(
            map(response => parseJsonWithDates<InvoiceRecord[]>(response))
        );
    }

    getInvoiceHistoryTest() {
        return of(JSON.stringify(invoiceHistory)).pipe(
            map(response => parseJsonWithDates<InvoiceHistoryRecord[]>(response))
        );
    }

    findCostCenter(sapBukrs: string, searchText: string) {
        const costCenterFindRequestData: CostCenterFindRequestData = {
            sapBukrs,
            searchText,
            limit: 50,
        }

        return this.http.post(`${REST_URL}/cost-centers/find`, costCenterFindRequestData).pipe(
            map(response => parseJsonWithDates<CostCenterRecord[]>(JSON.stringify(response || [])))
        );
    }

    findAccounts(sapSpras: string, searchText: string) {
        const accountFindRequestData: AccountFindRequestData = {
            sapSpras,
            searchText,
            limit: 50,
        }

        return this.http.post(`${REST_URL}/accounts/find`, accountFindRequestData).pipe(
            map(response => parseJsonWithDates<AccountRecord[]>(JSON.stringify(response || [])))
        );
    }

    getInvoice(invoiceId: string) {
        return this.http.get(`${REST_URL}/invoice/${invoiceId}`).pipe(
            map(response => parseJsonWithDates<InvoiceRecord>(JSON.stringify(response)))
        );
    }

    getInvoiceAttachment(invoiceId: string, id: number) {
        return this.http.get(`${REST_URL}/invoice/${invoiceId}/attachment/${id}`, {
            responseType: 'blob'
        });
    }

    getInvoiceCollection() {
        return this.http.get(`${REST_URL}/invoice/collection`).pipe(
            map(response => parseJsonWithDates<InvoiceRecord[]>(JSON.stringify(response || [])))
        );
    }

    getInvoiceHistory(invoiceId: string) {
        return this.http.get(`${REST_URL}/invoice/${invoiceId}/history`).pipe(
            map(response => parseJsonWithDates<InvoiceHistoryRecord[]>(JSON.stringify(response || [])))
        );
    }

    saveChanges(invoice: InvoiceRecord) {
        return this.http.post(`${REST_URL}/invoice/save`, {invoice}).pipe(
            map(response => parseJsonWithDates<InvoiceRecord>(JSON.stringify(response)))
        );
    }

    saveAndConfirm(invoice: InvoiceRecord, recipient?: string) {
        const requestData = {
            invoice,
            recipient: recipient || null,
        };

        return this.http.post(`${REST_URL}/invoice/save-and-confirm`, requestData).pipe(
            map(response => parseJsonWithDates<InvoiceRecord>(JSON.stringify(response)))
        );
    }

    addAttachment(invoiceId: string, attachment: File) {
        const formData = new FormData();
        formData.append("attachment", attachment, attachment.name);

        return this.http.post(`${REST_URL}/invoice/${invoiceId}/attachment`, formData).pipe();
    }

    removeAttachment(invoiceId: string, attachment: InvoiceAttachmentRecord) {
        return this.http.delete(`${REST_URL}/invoice/${invoiceId}/attachment/${attachment.id}`).pipe()
    }
}

function isIsoDateString(value: unknown): boolean {
    // Regex to match ISO 8601 date strings
    return typeof value === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z$/.test(value);
}

function convertDates<T>(key: string, value: T): Date | T {
    if (isIsoDateString(value)) {
        return new Date(value as string);
    }
    return value;
}

function parseJsonWithDates<T>(json: string): T {
    return JSON.parse(json, convertDates);
}
