<h2 mat-dialog-title>{{ 'edit-position' | translate }}</h2>

<mat-dialog-content>
    <form [formGroup]="editForm">
<!--        <mat-list class="custom-list">-->
<!--            <mat-list-item>-->
<!--                <span>{{ 'position-nr' | translate }}</span>-->
<!--                <span>{{ position.sapBuzei }}</span>-->
<!--            </mat-list-item>-->
<!--            <mat-list-item>-->
<!--                <span>{{ 'net-amount' | translate }}</span>-->
<!--                <span>{{ position.sapNetto }}</span>-->
<!--            </mat-list-item>-->
<!--        </mat-list>-->

        <mat-form-field appearance="outline">
            <mat-label>{{ 'text' | translate }}</mat-label>
            <input matInput type="text" formControlName="text">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'psp-element' | translate }}</mat-label>
            <input matInput type="text" formControlName="pspElement">
            @if (pspElement.hasError('maxlength')) {
                <mat-error>{{ 'edit-dialog.psp-length-error' | translate }}</mat-error>
            }
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ "order" | translate }}</mat-label>
            <input matInput type="text" formControlName="order">
        </mat-form-field>

        <!-- cost-center -->
        <mat-form-field appearance="outline">
            <mat-label>{{ "cost-center" | translate }}</mat-label>
            <input matInput type="search" formControlName="costCenter" [matAutocomplete]="autoCostCenter">
            <mat-autocomplete #autoCostCenter>
                @for (costCenter of costCenterFilter(); track $index) {
                    <mat-option [value]="costCenter.sapKostl + ' ' + costCenter.sapKostltext">{{ costCenter.sapKostl }} {{ costCenter.sapKostltext }}</mat-option>
                }
            </mat-autocomplete>
            <mat-hint>{{ "edit-dialog.search-select" | translate }}</mat-hint>
        </mat-form-field>

        <!-- account -->
        <mat-form-field appearance="outline">
            <mat-label>{{ "account" | translate }}</mat-label>
            <input matInput type="search" formControlName="account" [matAutocomplete]="autoAccount">
            <mat-autocomplete #autoAccount>
                @for (account of accountFilter(); track $index) {
                    <mat-option [value]="account.sapSaknr + ' ' + account.sapSaknrtext">{{ account.sapSaknr }} {{ account.sapSaknrtext }}</mat-option>
                }
            </mat-autocomplete>
            <mat-hint>{{ "edit-dialog.search-select" | translate }}</mat-hint>
        </mat-form-field>

    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-flat-button (click)="dialogRef.close()">{{ 'close' | translate }}</button>
    <button mat-flat-button (click)="savePositions()">{{ 'OK' }}</button>
</mat-dialog-actions>
