import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ToolbarComponent } from '../../shared/components/toolbar/toolbar.component';
import { InvoiceService } from '../../shared/services/invoice.service';
import { MatProgressBar } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { InvoiceRecord } from '../../shared/models/invoice';
import { ActivatedRoute } from '@angular/router';
import { OverviewAddressComponent } from './overview-address/overview-address.component';
import { OverviewInvoicesComponent } from './overview-invoices/overview-invoices.component';

@Component({
    selector: 'app-invoice-collection',
    standalone: true,
    imports: [
        ToolbarComponent,
        OverviewAddressComponent,
        OverviewInvoicesComponent,
        MatProgressBar,
        TranslateModule,
        OverviewInvoicesComponent
    ],
    templateUrl: './invoice-collection.component.html',
    styleUrl: './invoice-collection.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceCollectionComponent {
    private activatedRoute = inject(ActivatedRoute);
    private invoiceService = inject(InvoiceService);

    invoiceCollection = this.activatedRoute.snapshot.data['invoiceCollection'] as InvoiceRecord[];

    isLoading = this.invoiceService.isLoading;
}
