import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';

export const authConfig: AuthConfig = {
    // Url of the Identity Provider
    issuer: environment.issuer,

    // URL of the SPA to redirect the user to after login
    redirectUri: window.location.origin,

    preserveRequestedRoute: true,

    tokenEndpoint: environment.tokenEndpoint,

    // The SPA's id. The SPA is registered with this id at the auth-server
    clientId: environment.clientId,

    strictDiscoveryDocumentValidation: false,

    // Just needed if your auth server demands a secret. In general, this
    // is a sign that the auth server is not configured with SPAs in mind,
    // and it might not enforce further best practices vital for security
    // such applications.
    // dummyClientSecret: environment.clientSecret,

    responseType: 'code',

    // set the scope for the permissions the client should request
    // The first four are defined by OIDC.
    // Important: Request offline_access to get a refresh token
    scope: `${environment.clientId}/.default`,

    showDebugInformation: true,

    // Not recommended:
    // disablePKCI: true,
};
