<h2 mat-dialog-title>{{ "help-dialog.title" | translate }}</h2>

<mat-dialog-content>
    <div>
        <p class="text-bold">{{ "help-dialog.button-description" | translate }}</p>
        <mat-list class="help-list">
            @for (translation of getButtons(); track $index) {
                <mat-list-item>
                    <span><strong>{{ translation.name }}:</strong></span><br>
                    <span>{{ translation.description }}</span>
                </mat-list-item>
            }
        </mat-list>
    </div>

    <mat-divider/>

    <div>
        <p class="text-bold">{{ "help-dialog.table-description" | translate }}</p>
        <mat-accordion style="padding: 8px 0;">
            @for (translation of getAccountingTerms(); track $index) {
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{ translation.name }}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>{{ translation.description }}</p>
                </mat-expansion-panel>
            }
        </mat-accordion>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button (click)="dialogRef.close()" mat-flat-button>{{ 'close' | translate }}</button>
</mat-dialog-actions>