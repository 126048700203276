import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ToolbarComponent } from './shared/components/toolbar/toolbar.component';
import { InvoiceApprovalComponent } from './views/invoice-approval/invoice-approval.component';
import { AuthService } from './shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, ToolbarComponent, InvoiceApprovalComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
    private primeNGConfig = inject(PrimeNGConfig);
    private authService = inject(AuthService);
    private translateService = inject(TranslateService);

    constructor() {
        this.authService.initAuthConfiguration();

        this.translateService.setDefaultLang('en');
        const browserLang = this.translateService.getBrowserLang() ?? 'en';

        this.translateService.use(browserLang === 'de' ? 'de' : 'en');
        this.translateService.stream('primeng').subscribe(data => {
            this.primeNGConfig.setTranslation(data);
        });
    }
}
