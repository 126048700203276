import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatCard, MatCardContent, MatCardTitle } from '@angular/material/card';
import {
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle
} from '@angular/material/expansion';
import { MatList, MatListItem } from '@angular/material/list';
import { MatToolbar } from '@angular/material/toolbar';
import { MatButton } from '@angular/material/button';
import { MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatDivider } from '@angular/material/divider';
import { InvoiceService } from '../../../services/invoice.service';

@Component({
    selector: 'app-help-dialog',
    standalone: true,
    imports: [
        MatCard,
        MatCardTitle,
        MatCardContent,
        MatExpansionPanel,
        MatExpansionPanelTitle,
        MatExpansionPanelHeader,
        MatAccordion,
        MatListItem,
        MatList,
        MatToolbar,
        MatButton,
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        TranslateModule,
        MatDivider
    ],
    templateUrl: './help-dialog.component.html',
    styleUrl: './help-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpDialogComponent {
    invoiceService = inject(InvoiceService);
    dialogRef = inject(MatDialogRef);

    currentLang = this.invoiceService.currentLang;

    get currentTranslations() {
        switch (this.currentLang()) {
            case 'de':
                return translations.de;
            default:
                return translations.en;
        }
    }

    getButtons() {
        return this.currentTranslations.buttons;
    }

    getAccountingTerms() {
        return this.currentTranslations.accountingTerms;
    }
}

const translations = {
    en: {
        buttons: [
            {
                name: 'Display invoice',
                description: 'This button sets up a link to the archive system and displays the relevant voucher. Please note that invoice attachments might be listed separately in an attachment list (in the section \'Attachment\' of the form).'
            },
            {
                name: 'Help',
                description: 'Allows the user to view the glossary. Allows the user to view the button description.'
            },
            {
                name: 'Print',
                description: 'This button is used to print the approval form. (The invoice itself can only be printed via \'Display invoice\').'
            },
            {
                name: 'Add files',
                description: 'Click this button to choose a file to be attached from your computer. The following file formats are allowed: .doc, .xls, .rtf, .pdf, .jpg, .tif, .txt, .csv.'
            },
            {
                name: 'Recycle bin (icon)',
                description: 'You can use this button to remove your added attachments from the attachment list. To do this, click the recycle bin icon for each document.'
            },
            {
                name: 'True & Correct',
                description: 'This toggle is used to confirm goods/services receipt and the correctness of quantity, quality and price.'
            },
            {
                name: 'Everything True & Correct',
                description: 'This toggles all positions to factual correct.'
            },
            {
                name: 'Approved',
                description: 'This toggle is used by the acknowledging functional unit to confirm the correctness of the prices. If form processing has been ended by clicking the \'Price approval/rejection and send to accounting\' button, the following text is displayed in the form\'s info window: \'This invoice has been approved.\''
            },
            {
                name: 'Approve everything',
                description: 'This toggles all positions to price correct.'
            },
            {
                name: 'Edit (icon)',
                description: 'This opens a dialog window where different fields of the invoice position can be changed.'
            },
            {
                name: 'Save only',
                description: 'This button is used to save current changes to the invoice.'
            },
            {
                name: 'Complete',
                description: 'All outstanding changes are saved and the process is going to be completed.'
            },
            {
                name: 'Reject',
                description: 'Opens a dialog with a form to enter the reason for the rejection. Then the process is going to be rejected.'
            }
        ],
        accountingTerms: [
            {
                name: 'Creditor',
                description: 'Each supplier (account payable) is allocated a separate, unique number – the account payable number. The account payable master record contains the supplier\'s address and various information relevant to payment (bank details, payment terms, etc.).'
            },
            {
                name: 'Accounting information',
                description: 'This relates to all information required for proper accounting (account, cost center, order number where applicable, WBS element where applicable).'
            },
            {
                name: 'Cost Center',
                description: 'Each department at LH has its own cost center. This makes it possible to trace where the costs are incurred within a company.'
            },
            {
                name: 'WBS element',
                description: 'This is used to monitor project costs, and is mandatory for Invest bookings. Information can be obtained from your Controller.'
            }
        ]
    },
    de: {
        buttons: [
            {
                name: 'Hilfe',
                description: 'Ermöglicht dem Benutzer das Glossar anzuzeigen. Ermöglicht dem Benutzer die Schaltflächenbeschreibung anzusehen.'
            },
            {
                name: 'Rechnung anzeigen',
                description: 'Diese Schaltfläche richtet einen Link zum Archivsystem ein und zeigt den entsprechenden Beleg an. Bitte beachten Sie, dass Rechnungsanhänge möglicherweise separat in einer Anhangsliste aufgeführt sind (im Abschnitt "Anhang" des Formulars).'
            },
            {
                name: 'Drucken',
                description: 'Diese Schaltfläche wird verwendet, um das Genehmigungsformular zu drucken. (Die Rechnung selbst kann nur über "Rechnung anzeigen" gedruckt werden).'
            },
            {
                name: 'Dokumente anhängen',
                description: 'Klicken Sie auf diese Schaltfläche, um eine Datei von Ihrem Computer auszuwählen und anzuhängen. Die folgenden Dateiformate sind zulässig: .doc, .xls, .rtf, .pdf, .jpg, .tif, .txt, .csv.'
            },
            {
                name: 'Papierkorb (Symbol)',
                description: 'Sie können diese Schaltfläche verwenden, um Ihre hinzugefügten Anhänge aus der Anhangsliste zu entfernen. Klicken Sie dazu auf das Papierkorbsymbol für jedes Dokument.'
            },
            {
                name: 'Sachlich richtig',
                description: 'Mit diesem Schalter wird der Wareneingang und die Richtigkeit von Menge, Qualität und Preis bestätigt.'
            },
            {
                name: 'Alles sachlich richtig',
                description: 'Schaltet alle Positionen auf sachlich korrekt.'
            },
            {
                name: 'Preis richtig',
                description: 'Dieser Schalter wird von der bestätigenden Funktionseinheit verwendet, um die Richtigkeit der Preise zu bestätigen. Wenn die Formularbearbeitung durch Klicken auf die Schaltfläche "Preisgenehmigung/Ablehnung und an die Buchhaltung senden" beendet wurde, wird der folgende Text im Informationsfenster des Formulars angezeigt: "Diese Rechnung wurde genehmigt".'
            },
            {
                name: 'Alles genehmigen',
                description: 'Schaltet alle Positionen auf preislich korrekt.'
            },
            {
                name: 'Bearbeiten (Symbol)',
                description: 'Öffnet ein Dialogfenster, in dem verschiedene Felder der Rechnungsposition geändert werden können.'
            },
            {
                name: 'Speichern',
                description: 'Diese Schaltfläche wird verwendet, um aktuelle Änderungen an der Rechnung zu speichern.'
            },
            {
                name: 'Abschließen',
                description: 'Alle offenen Änderungen werden gespeichert und der Vorgang wird abgeschlossen.'
            },
            {
                name: 'Ablehnen',
                description: 'Öffnet ein Dialogfeld mit einem Formular, um den Grund für die Ablehnung einzugeben. Danach wird der Vorgang abgelehnt.'
            }
        ],
        accountingTerms: [
            {
                name: 'Kreditor',
                description: 'Jedem Lieferanten (Kreditor) wird eine separate, eindeutige Nummer zugewiesen – die Kreditorennummer. Der Kreditorenstammsatz enthält die Adresse des Lieferanten und verschiedene zahlungsrelevante Informationen (Bankverbindung, Zahlungsbedingungen, etc.).'
            },
            {
                name: 'Kontierung',
                description: 'Dies bezieht sich auf alle Informationen, die für eine ordnungsgemäße Buchhaltung erforderlich sind (Konto, Kostenstelle, falls zutreffend Auftragsnummer, WBS-Element falls zutreffend).'
            },
            {
                name: 'Kostenstelle',
                description: 'Jede Abteilung bei LH hat eine eigene Kostenstelle. Dies ermöglicht es, nachzuvollziehen, wo die Kosten innerhalb eines Unternehmens anfallen.'
            },
            {
                name: 'PSP-Element',
                description: 'Dieses wird zur Überwachung von Projektkosten verwendet und ist für Investbuchungen obligatorisch. Informationen erhalten Sie von Ihrem Controller.'
            }
        ]
    }
};
