import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { InvoiceRecord, InvoiceStatus } from '../../../../shared/models/invoice';

@Component({
    selector: 'app-email-dialog',
    standalone: true,
    imports: [
        MatDialogModule,
        MatFormFieldModule,
        MatButton,
        MatInput,
        TranslateModule,
        ReactiveFormsModule
    ],
    templateUrl: './email-dialog.component.html',
    styleUrl: './email-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailDialogComponent {
    dialogRef = inject(MatDialogRef<InvoiceRecord, string>);
    invoice = inject(MAT_DIALOG_DATA) as InvoiceRecord;

    emailForm = new FormGroup({
        email: new FormControl(this.getPrefillEmail(), {
            validators: [
                Validators.required,
                Validators.email
            ],
            nonNullable: true
        })
    });

    get email() {
        return this.emailForm.controls.email;
    }

    private getPrefillEmail() {
        return this.invoice.status === InvoiceStatus.Open ? this.invoice.sapEmpfangk : this.invoice.sapEmpfang1;
    }

    saveAndSend() {
        if (this.emailForm.valid && this.emailForm.value !== null) {
            this.dialogRef.close(this.email.value);
        }
    }
}
