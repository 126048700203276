import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { InvoiceService } from '../../../../shared/services/invoice.service';
import { InvoicePositionRecord } from '../../../../shared/models/invoice';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatHeaderCell } from '@angular/material/table';
import { MatInput } from '@angular/material/input';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { debounceTime, distinctUntilChanged, startWith, switchMap, tap } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { CostCenterRecord } from '../../../../shared/models/cost-center';
import { AccountRecord } from '../../../../shared/models/account';
import { MatList, MatListItem } from '@angular/material/list';

@Component({
    selector: 'app-edit-position-dialog',
    standalone: true,
    imports: [
        MatAutocompleteModule,
        MatDialogModule,
        MatButton,
        MatFormFieldModule,
        TranslateModule,
        MatHeaderCell,
        MatInput,
        ReactiveFormsModule,
        AsyncPipe,
        MatList,
        MatListItem
    ],
    templateUrl: './edit-position-dialog.component.html',
    styleUrl: './edit-position-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditPositionDialogComponent {
    invoiceService = inject(InvoiceService);
    position = inject(MAT_DIALOG_DATA) as InvoicePositionRecord;
    dialogRef = inject(MatDialogRef<InvoicePositionRecord, null>);

    currentCostCenter = signal<CostCenterRecord | null>(null);
    currentAccount = signal<AccountRecord | null>(null);
    currentLang = this.invoiceService.currentLang;

    costCenterFilter = signal<CostCenterRecord[]>([]);
    accountFilter = signal<AccountRecord[]>([]);

    editForm = new FormGroup({
        costCenter: new FormControl(`${this.position.sapKostla} ${this.position.sapKostltext}`, {
            validators: [
                Validators.required
            ],
            nonNullable: true
        }),
        account: new FormControl(
            `${this.position.sapHkonta} ${this.currentLang() === 'en' ? this.position.sapSaknrtextE : this.position.sapSaknrtextD}`, {
                validators: [
                    Validators.required
                ],
                nonNullable: true
            }),
        text: new FormControl(this.position.sapBezeka, {
            nonNullable: true
        }),
        pspElement: new FormControl(this.position.sapPsPosida, {
            validators: [
                Validators.maxLength(24)
            ],
            nonNullable: true
        }),
        order: new FormControl(this.position.sapAufnra, {
            nonNullable: true
        })
    });

    constructor() {
        this.costCenter.valueChanges.pipe(
            startWith(this.costCenter?.value || ''),
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((value) => this.filterCostCenter(value || ''))
        ).subscribe(costCenters => {
            this.costCenterFilter.set(costCenters);
        });

        this.account.valueChanges.pipe(
            startWith(this.account?.value || ''),
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((value) => this.filterAccount(value || ''))
        ).subscribe(accounts => {
            this.accountFilter.set(accounts);
        });
    }

    private filterCostCenter(value: string) {
        const filterValue = value.toLowerCase();

        return this.invoiceService.findCostCenter(filterValue).pipe(
            tap(costCenters => {
                if (costCenters.length === 1) {
                    this.currentCostCenter.set(costCenters[0]);
                }
            })
        );
    }

    private filterAccount(value: string) {
        const filterValue = value.toLowerCase();

        return this.invoiceService.findAccount(filterValue).pipe(
            tap(accounts => {
                if (accounts.length === 1) {
                    this.currentAccount.set(accounts[0]);
                }
            })
        );
    }

    get costCenter() {
        return this.editForm.controls.costCenter;
    }

    get account() {
        return this.editForm.controls.account;
    }

    get pspElement() {
        return this.editForm.controls.pspElement;
    }

    get order() {
        return this.editForm.controls.order;
    }

    get text() {
        return this.editForm.controls.text;
    }

    savePositions() {
        if (this.editForm.valid) {
            const editedPosition = {...this.position};
            const currentCostCenter = this.currentCostCenter();
            const currentAccount = this.currentAccount();

            if (currentCostCenter) {
                editedPosition.sapKostltext = currentCostCenter.sapKostltext || this.position.sapKostltext;
                editedPosition.sapKostla = currentCostCenter.sapKostl || this.position.sapKostla;
            } else {
                editedPosition.sapKostltext = this.position.sapKostltext;
                editedPosition.sapKostla = this.position.sapKostla;
            }

            if (currentAccount) {
                // TODO: This could be improved by providing all the available translations in the account result
                editedPosition.sapSaknrtextD = currentAccount.sapSaknrtext;
                editedPosition.sapSaknrtextE = currentAccount.sapSaknrtext;
                editedPosition.sapHkonta = currentAccount.sapSaknr;
            } else {
                editedPosition.sapSaknrtextD = this.position.sapSaknrtextD;
                editedPosition.sapSaknrtextE = this.position.sapSaknrtextE;
                editedPosition.sapHkonta = this.position.sapHkonta;
            }

            editedPosition.sapPsPosida = this.pspElement.value;
            editedPosition.sapAufnra = this.order.value;
            editedPosition.sapBezeka = this.text.value;

            this.dialogRef.close(editedPosition);
        }
    }
}
