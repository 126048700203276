import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ToolbarComponent } from '../../shared/components/toolbar/toolbar.component';

@Component({
    selector: 'app-not-found',
    standalone: true,
    imports: [
        TranslateModule,
        ToolbarComponent
    ],
    templateUrl: './not-found.component.html',
    styleUrl: './not-found.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class NotFoundComponent {

}
